import { environment } from '../../environments/environment';
let DEPLOY = '';

if (document.baseURI.toLowerCase().indexOf('localhost') !== -1) { // 本地或安卓
  DEPLOY = 'dev'; 
} else { // web端
  const a = document.baseURI.toLowerCase();
if (a.indexOf('yuyuan-dev') !== -1) {
    DEPLOY = 'dev'
  } else if (a.indexOf('yuyuan-stage') !== -1) {
    DEPLOY = 'stage'
  } else { // 测试地址
    DEPLOY = 'dev';
  }
}
DEPLOY = environment.production ? 'env' : DEPLOY;

const str = document.baseURI;

const FETCH = {
  'dev': {
    API_DOMAIN: 'https://yuyuan-dev.usequantum.com.cn',
    API_DIR: '/facts_backend-2.6/rest',
    UPLOAD_URL: 'https://yuyuan-dev.usequantum.com.cn',
    IOS_VERSION: '1.1.7',
    ANDROID_VERSION: '1.1.7',
  },
  'stage': {
    API_DOMAIN: 'https://yuyuan-stage.usequantum.com.cn',
    API_DIR: '/facts_backend-2.6/rest',
    UPLOAD_URL: 'https://yuyuan-stage.usequantum.com.cn',
    IOS_VERSION: '1.1.7',
    ANDROID_VERSION: '1.1.7',
  },
  'env': {
    API_DOMAIN: 'https://ehsq.yuyuantm.com.cn',
    API_DIR: '/facts_backend-2.6/rest',
    UPLOAD_URL: 'https://ehsq.yuyuantm.com.cn',
    IOS_VERSION: '1.1.7',
    ANDROID_VERSION: '1.1.7',
  },
};
export const APPConfig = FETCH[DEPLOY];
