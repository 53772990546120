import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataService, AppService, LocalStorageService } from '../../service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController, PickerController, IonSlides, PopoverController } from '@ionic/angular';
import { PickerOptions } from "@ionic/core";
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi
import { NewPageSelectComponent } from '../../shared/components/new-page-select/new-page-select.component';
import { AddFindDialogComponent } from '../add-find-dialog/add-find-dialog.component';
import { RecificationDetailDialogComponent } from './recification-detail-dialog/recification-detail-dialog.component';
import { PopoverDialogComponent } from 'src/app/shared/components/popover-dialog/popover-dialog.component';

@Component({
  selector: 'app-rectification-measures',
  templateUrl: './rectification-measures.component.html',
  styleUrls: ['./rectification-measures.component.scss'],
})
export class RectificationMeasuresComponent implements OnInit {
  staffList = [];
  genderList = [];
  injuredPartsList = [];
  injuredPartsProblemList = [];
  classificationList = [];
  individualInfo: any = {};
  currentEmployeeAge = 0;
  injuredPartsDisplay = '';
  projectCostCenterCodeList = [];
  selectedProjectCostCenter;
  isInvestigation: boolean;
  org;
  insurance = [];  // 保险情况下拉code
  editable = false;
  attachIds = '';
  involvedEmployee = [];
  rectificationMeasures = [];
  memberExpanded = true;

  pageIndex = 0;
  reviewClick;
  isTwo;
  idx;
  listStatus;

  @ViewChild('picker') picker: IonSlides; // 上传文件的输入框

  constructor(
    public dataService: DataService,
    public appService: AppService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalController: ModalController,
    public pickerController: PickerController,
    public popoverController: PopoverController,
    public localStorageService: LocalStorageService) {
    let params = this.navParams.data;
    // this.staffList = params.staffList;
    this.org = params.org;
    this.idx = params.idx;
    this.listStatus = params.status ? params.status : '';

    this.isTwo = params.isTwo ? params.isTwo : 'show';
    this.isInvestigation = params.isInvestigation;
    this.individualInfo = params.currentData ? params.currentData : {};
    this.editable = params.editable ? params.editable : false;
    this.involvedEmployee = params.involvedEmployee;
    this.reviewClick = params.reviewClick ? params.reviewClick : false;
    this.involvedEmployee.forEach(item => {
      item.rectification = item.rectification ? item.rectification : [];

      item.rectification.forEach(element => {
        if (element.attachments) {
          element.basicInfoAttachments = '';
          element.attachments.forEach(file => {
            element.basicInfoAttachments += (file.fileId + ',');
          });
          if (element.basicInfoAttachments) {
            element.basicInfoAttachments = element.basicInfoAttachments.substr(0, element.basicInfoAttachments.length - 1);
          }
        }
      });
    });
    console.log('最终答案', this.involvedEmployee);

    const that = this;
    window.onpopstate = function () {
      const a = document.getElementsByTagName('app-rectification-measures');
      if (a.length > 0) {
        setTimeout(() => {
          that.modalController.dismiss();
          // window.history.forward();
        }, 100)
      }
    };
  }

  ionViewDidEnter() {
    this.ngOnInit();
  }

  ngOnInit() {
    if (this.idx) {
      this.picker.slideTo(this.idx);
    }
    // 设置标题
    document.getElementsByTagName('title').item(0).innerText = '隐患上报';
    if (dd.env.platform !== 'notInDingTalk') {
      dd.biz.navigation.setTitle({
        title: '隐患上报' // 钉钉设置导航栏标题
      });
    }
    this.getInjuredPartsProblemList();
    this.getInjuredPartsList();
    this.getClassificationList();
    this.getInsuranceList();
  }

  // 风险级别
  async getInjuredPartsList(): Promise<any> {
    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      that.dataService.codesChildrenOf('_SYS_AX').subscribe(res => {
        if (res && res.STATUS === 0) {
          that.injuredPartsList = res.DATA[0].childCodes;
          resolve();
        } else {
          this.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

  // 根本原因
  async getInjuredPartsProblemList(): Promise<any> {
    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      that.dataService.codesChildrenOf('_SYS_WH_6').subscribe(res => {
        if (res && res.STATUS === 0) {
          that.injuredPartsProblemList = res.DATA[0].childCodes;
          resolve();
        } else {
          this.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

  // 获取受伤部位列表
  async getClassificationList(): Promise<any> {
    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      that.dataService.codesChildrenOf('_SYS_DC').subscribe(res => {
        if (res && res.STATUS === 0) {
          that.classificationList = res.DATA[0].childCodes;
          resolve();
        } else {
          this.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

  async selectInjuredParts() {
    if (!this.editable) {
      const modal = await this.modalController.create({
        component: NewPageSelectComponent,
        componentProps: {
          pageTitle: '受伤部位',
          placeholderMessage: 'aramark_injured_parts',
          originalOptionList: this.injuredPartsList,
          displayField: 'code_desc_zh',
          valueField: 'code',
          multiSelect: false,
          needTranslation: false,
          cancelText: 'aramark_cancel',
          confirmText: 'aramark_confirm',
          selected: this.individualInfo.riskLevel ? this.individualInfo.riskLevel : []
        },
        showBackdrop: true,
        backdropDismiss: true
      });
      modal.onDidDismiss().then(res => {
        // 设置标题
        document.getElementsByTagName('title').item(0).innerText = '隐患上报';
        if (dd.env.platform !== 'notInDingTalk') {
          dd.biz.navigation.setTitle({
            title: '隐患上报' // 钉钉设置导航栏标题
          });
        }
        if (res.data) {
          this.individualInfo.riskLevel = res.data;
        }
      });
      return await modal.present();
    }
  }

  // 隐患分类
  async selectClassification() {
    if (!this.editable) {
      const modal = await this.modalController.create({
        component: NewPageSelectComponent,
        componentProps: {
          pageTitle: '受伤部位',
          placeholderMessage: 'aramark_injured_parts',
          originalOptionList: this.classificationList,
          displayField: 'code_desc_zh',
          valueField: 'code',
          multiSelect: false,
          needTranslation: false,
          cancelText: 'aramark_cancel',
          confirmText: 'aramark_confirm',
          selected: this.individualInfo.classification ? this.individualInfo.classification : []
        },
        showBackdrop: true,
        backdropDismiss: true
      });
      modal.onDidDismiss().then(res => {
        // 设置标题
        document.getElementsByTagName('title').item(0).innerText = '隐患上报';
        if (dd.env.platform !== 'notInDingTalk') {
          dd.biz.navigation.setTitle({
            title: '隐患上报' // 钉钉设置导航栏标题
          });
        }
        if (res.data) {
          this.individualInfo.classification = res.data;
        }
      });
      return await modal.present();
    }
  }

  async cancel() {
    await this.modalController.dismiss();
  }

  async save() {
    console.log(this.individualInfo, this.rectificationMeasures, this.involvedEmployee);
    if (this.reviewClick) {
      let flag = false;
      // this.involvedEmployee.forEach(element => {
      if (!(this.involvedEmployee[this.pageIndex].riskLevel && this.involvedEmployee[this.pageIndex].classification)) {
        flag = true;
        // return;
      }
      // });
      if (flag) {
        this.appService.toastTip('您还没填写风险级别或隐患分类，无法提交！', true);
        return;
      }


      let submitData = {};
      let find: any = {};
      let finds: any = [];
      this.involvedEmployee.forEach(item => {
        find = {};
        find.attachments = item.attachments;
        find.finding = item.finding;
        find.id = item.id;
        find.inspectionObject = {};
        find.inspectionObject.displayObjectName = item.displayObjectName;
        find.inspectionObject.id = item.displayId;
        find.inspectionObject.objectType = "_SYS_AA_Z";
        find.inspectionObject.sequence = null;
        find.inspectionObjectId = item.displayId;
        find.riskLevel = item.riskLevel;
        find.classification = item.classification;
        find.isActive = item.isActive;
        find.investigations = [];
        if (item.rectification) {
          item.rectification.forEach(element => {
            let investigations: any = {};
            investigations.actionMode = '_SYS_CA_AM_1';
            investigations.deviationId = null;
            investigations.editable = true;
            investigations.singleReport = {
              status: '_SYS_CA_1'
            };
            // investigations.singleReport.attachments = element.attachments;
            investigations.employee = element.employee;
            investigations.id = element.id ? element.id : null;
            investigations.investigationCauseList = element.investigationCauseList;
            investigations.singleEstimateEndDate = element.singleEstimateEndDate;
            investigations.singleSuggestedAction = element.singleSuggestedAction;
            investigations.subject = element.subject;
            investigations.target = element.department;
            find.investigations.push(investigations);
          });
        }
        finds.push(find);
      });
      this.dataService.saveInspectionTasksList(finds, this.activatedRoute.snapshot.queryParams.taskId).subscribe(res => {
        if (res && res.STATUS === 0) {
          this.appService.toastTip('保存成功', true);
          this.modalController.dismiss(this.involvedEmployee);
        } else {
          this.appService.toastTip(res.MSG, true);
        }
      });
    } else {
      await this.modalController.dismiss(this.involvedEmployee);
    }
  }

  requiredFieldsCompleted() {
    return true;
  }

  async getInsuranceList(): Promise<any> {
    let promise = new Promise<void>((resolve, reject) => {
      const that = this;
      that.dataService.codesChildrenOf('_SYS_WH_9').subscribe(res => {
        if (res && res.STATUS === 0) {
          that.insurance = res.DATA[0].childCodes;
          resolve();
        } else {
          that.appService.toastTip('get_data_failed_key', true);
          reject();
        }
      });
    });
    return promise;
  }

  translateCode(code, translationList: any[]) {
    for (const item of translationList) {
      if (code === item.code) {
        return item.code_desc_zh;
      }
    }
    return '';
  }

  // 更新选择的事故日期格式
  changeIncidentDateFormat(event) {
    let selectedDate = event.detail.value;
    if (selectedDate) {
      this.individualInfo.returnToWorkDate = new Date(selectedDate).toISOString();
    }
  }

  // 上传附件
  updateIncidentReportPictures(files, i) {
    console.log(files);
    this.attachIds = files;
    const array = this.attachIds.split(',');
    this.involvedEmployee[this.pageIndex].rectification[i].attachments = this.involvedEmployee[this.pageIndex].rectification[i].attachments ? this.involvedEmployee[this.pageIndex].rectification[i].attachments : [];
    array.forEach(v => {
      const f = this.involvedEmployee[this.pageIndex].rectification[i].attachments.find(i => parseInt(i.fileId) === parseInt(v));
      if (!f || f.length === 0) {
        this.involvedEmployee[this.pageIndex].rectification[i].attachments.push({ fileId: v });
      }
    });
  }


  async editPartyIndividual(index, item, previousType) {
    // let editable;
    // if (this.listStatus == '_SYS_PL1_E') {
    //   editable = true;
    // } else {
    //   editable = false;
    // }
    const data = JSON.parse(JSON.stringify(item));
    const modal = await this.modalController.create({
      component: AddFindDialogComponent,
      componentProps: {
        staffList: this.staffList,
        currentData: data,
        reviewClick: this.reviewClick,
        flag: 'edit',
        editable: this.editable
      },
      showBackdrop: true,
      backdropDismiss: true
    });
    modal.onDidDismiss().then(res => {
      // 设置标题
      document.getElementsByTagName('title').item(0).innerText = '隐患上报';
      if (dd.env.platform !== 'notInDingTalk') {
        dd.biz.navigation.setTitle({
          title: '隐患上报' // 钉钉设置导航栏标题
        });
      }
      let employee = res.data;
      if (employee) {
        this.involvedEmployee[index] = employee;
      }
    });
    return await modal.present();
  }

  // 改变展开收起
  toggleArea(area: string) {
    this[area] = !this[area];
  }

  // 添加整改措施
  async addPartyIndividual() {
    history.pushState(null, null, document.URL);
    const modal = await this.modalController.create({
      component: RecificationDetailDialogComponent,
      componentProps: {
        staffList: this.staffList,
        org: this.org
      },
      showBackdrop: true,
      backdropDismiss: true
    });
    modal.onDidDismiss().then(res => {
      // 设置标题
      document.getElementsByTagName('title').item(0).innerText = '隐患上报';
      if (dd.env.platform !== 'notInDingTalk') {
        dd.biz.navigation.setTitle({
          title: '隐患上报' // 钉钉设置导航栏标题
        });
      }
      let employee = res.data;
      if (employee) {
        // employee.injuredPart = [];
        // if (employee.employeeType === '_SYS_JT5_A') {
        //   this.involvedEmployee.push(employee);
        //   const data = {
        //     duty: '_INCIDENT_D1_5',
        //     employee: employee.employee,
        //     isDefault: true,
        //     notifierType: '_REPORT_NOTIFIER_A'    // 事故上报时的通知对象
        //   };
        //   this.basicInfo.notifier.push(data);
        // } else if (employee.employeeType === '_SYS_JT5_C' || employee.employeeType === '_SYS_JT5_D') {
        //   this.involvedContractor.push(employee);
        // }
        this.involvedEmployee[this.pageIndex].rectification.push(employee);
      }
    });
    return await modal.present();
  }

  async editRectificationPartyIndividual(index, item) {
    const data = JSON.parse(JSON.stringify(item));
    const modal = await this.modalController.create({
      component: RecificationDetailDialogComponent,
      componentProps: {
        staffList: this.staffList,
        currentData: data,
        flag: 'edit',
        isTwo: this.isTwo,
        editable: this.editable
      },
      showBackdrop: true,
      backdropDismiss: true
    });
    modal.onDidDismiss().then(res => {
      // 设置标题
      document.getElementsByTagName('title').item(0).innerText = '隐患上报';
      if (dd.env.platform !== 'notInDingTalk') {
        dd.biz.navigation.setTitle({
          title: '隐患上报' // 钉钉设置导航栏标题
        });
      }
      console.log('判断取消保存内容滴滴滴', res);
      if (res.data) {
        if (res.data == 'delete') {
          this.involvedEmployee[this.pageIndex].rectification.splice(index, 1);
        } else {
          let employee = res.data;
          this.involvedEmployee[this.pageIndex].rectification[index] = employee;
        }
      }
    });
    return await modal.present();
  }

  translateCodes(code, translationList: any[]) {
    if (code && translationList) {
      let code_desc_zhList: any[] = [];
      code.forEach(item => {
        translationList.forEach(element => {
          if (item === element.code) {
            code_desc_zhList.push(element.code_desc_zh);
          }
        });
      });
      return code_desc_zhList.join(',');
    }
  }

  openImageGallery() {
    this.picker.getActiveIndex().then(data => {
      this.pageIndex = data;
    });
  }

  async deletePartyIndividual() {

    const popover = await this.popoverController.create({
      component: PopoverDialogComponent,
      componentProps: {
        title: '是否删除隐患上报',
        format: ['cancel', 'confirm']
      },
      showBackdrop: true,
      backdropDismiss: false
    });

    popover.onDidDismiss().then(res => {
      if (res.data && res.data === 'confirm') {
        this.involvedEmployee.splice(this.pageIndex, 1);
        console.log(this.involvedEmployee);
        this.modalController.dismiss(this.involvedEmployee);
      }
    });
    return await popover.present();
  }

  // 标记无效 标记有效
  changeIsActive(flag) {
    if (flag) {
      this.involvedEmployee[this.pageIndex].isActive = false;
    } else {
      this.involvedEmployee[this.pageIndex].isActive = true;
    }
  }
}
