import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService, AppService, LocalStorageService } from '../service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController, PickerController } from '@ionic/angular';
import { PickerOptions } from "@ionic/core";
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi
import { NewPageSelectComponent } from '../shared/components/new-page-select/new-page-select.component';

@Component({
  selector: 'app-add-party-individual',
  templateUrl: './add-party-individual.component.html',
  styleUrls: ['./add-party-individual.component.scss'],
})
export class AddPartyIndividualComponent implements OnInit {
  staffList = [];
  genderList = [];
  injuredPartsList = [];
  individualInfo: any = {};
  currentEmployeeAge = 0;
  injuredPartsDisplay = '';
  projectCostCenterCodeList = [];
  selectedProjectCostCenter;
  isInvestigation: boolean;
  org;
  insurance = [];  // 保险情况下拉code
  editable = false;
  staffType = '';
  isStaff = true;
  staffSearchQuery = [];
  staffTotal = 0;
  constructor(
    public dataService: DataService,
    public appService: AppService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalController: ModalController,
    public pickerController: PickerController,
    public localStorageService: LocalStorageService) {
    let params = this.navParams.data;
    // this.staffList = params.staffList;
    this.org = params.org;
    this.isInvestigation = params.isInvestigation;
    this.individualInfo = params.currentData ? params.currentData : {};
    this.editable = params.editable ? params.editable : false;
    // console.log(this.editable);
    this.individualInfo.isInjured = this.individualInfo.isInjured ? this.individualInfo.isInjured : false;
    this.individualInfo.needHospitalization = this.individualInfo.needHospitalization ? this.individualInfo.needHospitalization : false;
    this.individualInfo.needOnSiteTreatment = this.individualInfo.needOnSiteTreatment ? this.individualInfo.needOnSiteTreatment : false;

    this.selectedProjectCostCenter = this.individualInfo.department ? this.individualInfo.department : this.org;
    this.individualInfo.department = this.individualInfo.department ? this.individualInfo.department : this.org;

    this.individualInfo.returnedToWork = this.individualInfo.returnedToWork === '_SYS_AM_1' ? true : false;
    this.individualInfo.affectWork = this.individualInfo.affectWork === '_SYS_AM_1' ? true : false;
    this.individualInfo.onVacation = this.individualInfo.onVacation === '_SYS_AM_1' ? true : false;
    this.individualInfo.hospitalized = this.individualInfo.hospitalized === '_SYS_AM_1' ? true : false;

    // this.individualInfo.employeeType = this.individualInfo.employeeType !== undefined ? (this.individualInfo.employeeType === '_SYS_JT5_A' ? true : this.individualInfo.employeeType) : true;
    const data = JSON.parse(JSON.stringify(this.individualInfo));

    if (data['employeeType'] !== undefined) {
      if (data['employeeType'] === '_SYS_JT5_A') {
        this.isStaff = true;
      } else {
        this.isStaff = false;
        this.staffType = this.individualInfo.employeeType;
      }
    } else {
      // this.individualInfo['employeeType'] = '_SYS_JT5_A';
      this.isStaff = true;
    }
  }

  ionViewDidEnter() {
    this.ngOnInit();
  }

  ngOnInit() {
    // 设置标题
    document.getElementsByTagName('title').item(0).innerText = '事故当事人';
    if (dd.env.platform !== 'notInDingTalk') {
      dd.biz.navigation.setTitle({
        title: '事故当事人' // 钉钉设置导航栏标题
      });
    }
    this.getGenderList();
    this.getInjuredPartsList();
    this.getProjectCostCenterCodeList();
    // 获取对应的组织架构下面的人员list
    if (this.selectedProjectCostCenter && this.selectedProjectCostCenter.id) {
      this.getStaffList();
    }
    this.getInsuranceList();

    const that = this;
    window.onpopstate = function () {
      const a = document.getElementsByTagName('app-add-party-individual');
      if (a.length > 0) {
        setTimeout(() => {
          that.modalController.dismiss();
          // window.history.forward();
        }, 100)
      }
    };
  }

  // 获取受伤部位列表
  async getInjuredPartsList(): Promise<any> {
    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      that.dataService.codesChildrenOf('_SYS_WH_4').subscribe(res => {
        if (res && res.STATUS === 0) {
          that.injuredPartsList = res.DATA[0].childCodes;
          resolve();
        } else {
          this.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

  // 获取性别列表
  async getGenderList(): Promise<any> {
    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      that.dataService.codesChildrenOf('_SYS_A3').subscribe(res => {
        if (res && res.STATUS === 0) {
          that.genderList = res.DATA[0].childCodes;
          resolve();
        } else {
          this.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

  // 将性别列表转换为组件接受的格式
  getGenderOptions() {
    let options = [];
    this.genderList.forEach(item => {
      options.push({ text: item.code_desc_zh, value: item.code });
    });
    return options;
  }

  // 显示性别页面选择元素
  async showGenderPicker() {
    if (!this.editable) {
      let options: PickerOptions = {
        buttons: [
          {
            text: '取消',
            role: 'cancel'
          },
          {
            text: '确认',
            handler: (value: any) => {
              this.individualInfo.contractorGender = value.gender.value;
            }
          }
        ],
        columns: [
          {
            name: 'gender',
            options: this.getGenderOptions()
          }
        ]
      };

      let picker = await this.pickerController.create(options);
      return picker.present();
    }
  }

  async selectStaff() {
    if (!this.editable) {
      history.pushState(null, null, document.URL);

      const modal = await this.modalController.create({
        component: NewPageSelectComponent,
        componentProps: {
          pageTitle: '员工',
          placeholderMessage: 'aramark_staff',
          originalOptionList: this.staffList,
          displayField: ['code', 'lastName'],
          valueField: '$self',
          multiSelect: false,
          format: '[$1] $2',
          cancelText: 'aramark_cancel',
          confirmText: 'aramark_confirm',
          selected: this.individualInfo.employee,
          isStaff: true,
          searchQuery: this.staffSearchQuery,
          staffTotal: this.staffTotal
        },
        showBackdrop: true,
        backdropDismiss: true
      });
      modal.onDidDismiss().then(res => {
        // 设置标题
        document.getElementsByTagName('title').item(0).innerText = '添加事故当事人';
        if (dd.env.platform !== 'notInDingTalk') {
          dd.biz.navigation.setTitle({
            title: '添加事故当事人' // 钉钉设置导航栏标题
          });
        }
        if (res.data) {
          this.individualInfo.employee = res.data;
          this.individualInfo.employeeId = res.data.id;
          this.currentEmployeeAge = this.calculateEmployeeAge(new Date(res.data.birthDate));
        }
      });
      return await modal.present();
    }
  }

  async selectInjuredParts() {
    if (!this.editable) {
      history.pushState(null, null, document.URL);

      const modal = await this.modalController.create({
        component: NewPageSelectComponent,
        componentProps: {
          pageTitle: '受伤部位',
          placeholderMessage: 'aramark_injured_parts',
          originalOptionList: this.injuredPartsList,
          displayField: 'code_desc_zh',
          valueField: 'code',
          multiSelect: true,
          needTranslation: false,
          cancelText: 'aramark_cancel',
          confirmText: 'aramark_confirm',
          selected: this.individualInfo.injuredPart ? this.individualInfo.injuredPart : []
        },
        showBackdrop: true,
        backdropDismiss: true
      });
      modal.onDidDismiss().then(res => {
        // 设置标题
        document.getElementsByTagName('title').item(0).innerText = '事故快报';
        if (dd.env.platform !== 'notInDingTalk') {
          dd.biz.navigation.setTitle({
            title: '事故快报' // 钉钉设置导航栏标题
          });
        }
        if (res.data) {
          this.individualInfo.injuredPart = res.data;
        }
      });
      return await modal.present();
    }
  }

  async cancel() {
    await this.modalController.dismiss();
  }

  validateNum(val) {
    val = val.replace(/[^0-9]/g,'');
  }

  save() {
    if (!this.requiredFieldsCompleted()) {
      return;
    }

    if (this.isInvestigation) {
      if (!this.individualInfo.returnToWorkDate) {
          this.appService.toastTip('无法保存，返工日期在填写调查报告时必填！', true);
          return;
      }
    }

    if (this.isStaff) {
      this.individualInfo.employeeType = '_SYS_JT5_A';
    } else {
      this.individualInfo.employeeType = this.staffType;
    }

    // if (this.individualInfo.employeeType === true) {
    // 	this.individualInfo.employeeType = '_SYS_JT5_A';
    // } else if (this.individualInfo.employeeType === false) {
    // 	this.individualInfo.employeeType = this.staffType;
    // }

    this.individualInfo.returnedToWork = this.individualInfo.returnedToWork === true ? '_SYS_AM_1' : '_SYS_AM_2';
    this.individualInfo.hospitalized = this.individualInfo.hospitalized === true ? '_SYS_AM_1' : '_SYS_AM_2';
    this.individualInfo.affectWork = this.individualInfo.affectWork === true ? '_SYS_AM_1' : '_SYS_AM_2';
    this.individualInfo.onVacation = this.individualInfo.onVacation === true ? '_SYS_AM_1' : '_SYS_AM_2';
    this.individualInfo.injuredPart = this.individualInfo.injuredPart ? this.individualInfo.injuredPart : [];
    if (this.individualInfo.employeeType !== '_SYS_JT5_A') {
      delete this.individualInfo.onVacation;
      delete this.individualInfo.returnedToWork;
      delete this.individualInfo.affectWork;
      delete this.individualInfo.hospitalized;
      // delete this.individualInfo.isInjured;
      // delete this.individualInfo.needHospitalization;
      // delete this.individualInfo.needOnSiteTreatment;
      delete this.individualInfo.department;
    }
    const data = JSON.parse(JSON.stringify(this.individualInfo))
    this.modalController.dismiss(data);
  }

  calculateEmployeeAge(birthDate: Date) {
    return (new Date().getFullYear() - birthDate.getFullYear() + 1);
  }

  updateInjuredPartsDisplay() {
    if (!this.individualInfo.injuredPart || this.individualInfo.injuredPart.length === 0) {
      return '';
    }
    let displayText = '';
    this.individualInfo.injuredPart.forEach(item => {
      for (let code of this.injuredPartsList) {
        if (code.code === item) {
          displayText += (code.code_desc_zh + ',');
          break;
        }
      }
    });
    displayText = displayText.substr(0, displayText.length - 1);
    return displayText;
  }

  requiredFieldsCompleted() {
    if (!this.individualInfo) {
      return false;
    }
    // if (!this.individualInfo.employeeType) {
    // 	return false;
    // }
    if (this.isStaff == true) {
      if (!this.individualInfo.employee) {
        return false;
      } else {
        return true;
      }
    } else if (this.staffType === '_SYS_JT5_C' || this.staffType === '_SYS_JT5_D') {
      if (this.individualInfo.contractorEmployee && this.individualInfo.contractorAge && this.individualInfo.contractorGender) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  // 获取组织架构数据
  async getProjectCostCenterCodeList(): Promise<any> {
    const param = {
      pagingTool: {
        currentPage: 1,
        pageSize: 1000
      },
      queryCriterias: [{
        condition: "like",
        connection: "and",
        isValueADigital: false,
        key: "org_type",
        value: "_SYS_A8_5",
      }],
      queryOrderBies: [{
        columnName: 'code',
        orderType: 'ASC'
      }]
    };
    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      that.dataService.getProjectCostCenterCodeListTable(param).subscribe(res => {
        if (res && res.STATUS === 0) {
          that.projectCostCenterCodeList = res.DATA;
          resolve();
        } else {
          that.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

  // 选取组织架构
  async selectProjectCostCenterCode() {
    if (!this.editable) {
      history.pushState(null, null, document.URL);

      const modal = await this.modalController.create({
        component: NewPageSelectComponent,
        componentProps: {
          pageTitle: '组织架构',
          placeholderMessage: 'aramark_project_cost_center_code',
          originalOptionList: this.projectCostCenterCodeList,
          displayField: ['code', 'full_name'],
          valueField: '$self',
          multiSelect: false,
          format: '[$1] $2',
          cancelText: 'aramark_cancel',
          confirmText: 'aramark_confirm',
          selected: this.selectedProjectCostCenter
        },
        showBackdrop: true,
        backdropDismiss: true
      });
      modal.onDidDismiss().then(res => {
        // 设置标题
        document.getElementsByTagName('title').item(0).innerText = '事故快报';
        if (dd.env.platform !== 'notInDingTalk') {
          dd.biz.navigation.setTitle({
            title: '事故快报' // 钉钉设置导航栏标题
          });
        }
        if (res.data) {
          this.selectedProjectCostCenter = res.data;
          this.individualInfo.department = res.data;
          this.individualInfo.employee = null;
          this.getStaffList();
        }
      });
      return await modal.present();
    }
  }

  async getStaffList(): Promise<any> {
    const param = {
      pagingTool: {
        currentPage: 1,
        pageSize: 9000
      },
      queryCriterias: [
        {
          'connection': '=',
          'key': 'org_ids',
          'condition': 'and',
          'value': this.selectedProjectCostCenter.id,
          'isValueADigital': false
        },
      ],
      queryOrderBies: [{
        columnName: 'code',
        orderType: 'DESC'
      }]
    };
    const that = this;
    this.staffSearchQuery = [
      {
        'connection': '=',
        'key': 'org_ids',
        'condition': 'and',
        'value': this.selectedProjectCostCenter.id,
        'isValueADigital': false
      },
    ]
    const promise = new Promise<void>((resolve, reject) => {
      that.dataService.getStaffList(param).subscribe(res => {
        if (res && res.STATUS === 0) {
          that.staffList = res.DATA;
          that.staffTotal = res.TOTAL_COUNT;
          resolve();
        } else {
          that.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

  async getInsuranceList(): Promise<any> {
    let promise = new Promise<void>((resolve, reject) => {
      const that = this;
      that.dataService.codesChildrenOf('_SYS_WH_9').subscribe(res => {
        if (res && res.STATUS === 0) {
          that.insurance = res.DATA[0].childCodes;
          resolve();
        } else {
          that.appService.toastTip('get_data_failed_key', true);
          reject();
        }
      });
    });
    return promise;
  }

  translateCode(code, translationList: any[]) {
    for (const item of translationList) {
      if (code === item.code) {
        return item.code_desc_zh;
      }
    }
    return '';
  }

  // 更新选择的事故日期格式
  changeIncidentDateFormat(event) {
    let selectedDate = event.detail.value;
    if (selectedDate) {
      this.individualInfo.returnToWorkDate = new Date(selectedDate).toISOString();
    }
  }
}
