import { Component, OnInit } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { DataService, LocalStorageService, AppService } from 'src/app/service';

@Component({
  selector: 'app-corp-select',
  templateUrl: './corp-select.component.html',
  styleUrls: ['./corp-select.component.scss'],
})
export class CorpSelectComponent implements OnInit {
  orgId: number; // 当前用户所属的最高组织架构
  selectedOrg: number; // 被选中的组织架构
  orgCrumbs = []; // 组织架构面包屑
  orgList = []; // 组织架构列表
  originalOrgList = []; // 组织架构列表-源数据
  search: string; // 搜索文本
  allOrgList = [];
  instantClose;
  isLoading = false;
  
  constructor(private navParams: NavParams,
    private modalController: ModalController,
    private appService: AppService,
    private dataService: DataService,
    private localStorageService: LocalStorageService) { 
      let params = this.navParams.data;
      this.selectedOrg = params.selectedOrg ? params.selectedOrg : null;
    }

  ngOnInit() {
    // 获取所有的组织架构数据
    this.dataService.getCorporatesChildrenById(-1).subscribe(res => {
      this.orgList = res.DATA;
      this.orgList = this.orgList.concat(res.DATA[0].childCorporates);
    });

    const that = this;
    window.onpopstate = function () {
      const a = document.getElementsByTagName('app-corp-select');
      if (a.length > 0) {
        setTimeout(() => {
          that.modalController.dismiss();
        }, 100)
      }
    };
  }

  // 根据父id获取子组织架构
  getChildrenCorporate(id) {
    this.isLoading = true;
    this.dataService.getCorporatesChildrenById(id).subscribe(res => {
      if (res.STATUS === 0) {
        this.orgList = res.DATA;
        this.originalOrgList = res.DATA;
        this.isLoading = false;
      }
    }, (err) => {
      this.appService.toastTip('get_data_failed_key', true);
      this.isLoading = false;
    });
  }

  // 点击组织架构面包屑
  clickOrgCrumbs(org, index) {
    if (index === this.orgCrumbs.length - 1 || this.orgCrumbs.length === 0) {
      return;
    } else {
      this.orgCrumbs = this.orgCrumbs.splice(0, index + 1);
      this.getChildrenCorporate(org.id);
    }
  }

  // 点击组织架构
  clickOrg(org) {
    this.selectedOrg = org;
  }

  // 搜索组织架构
  searchOrg() {
    // if (this.search) {
    //   this.orgList = this.allOrgList.filter(res => res.full_name.indexOf(this.search) !== -1);
    // } else {
    //   Object.assign(this.orgList, this.originalOrgList);
    // }
    if (this.search) {
      this.orgCrumbs = []; 
      const param = {
        pagingTool: {
          currentPage: 1,
          pageSize: 9999
        },
        queryCriterias: [{
          condition: "like",
          connection: "and",
          isValueADigital: false,
          key: "code",
          value: this.search,
        }],
        queryOrderBies: []
      };
      const that = this;
      const promise = new Promise<void>((resolve, reject) => {
        that.dataService.getProjectCostCenterCodeListTable(param).subscribe(res => {
          if (res && res.STATUS === 0) {
            // event.target.complete();
            // this.currentPage++;
            // this.originalOptionList = this.originalOptionList.concat(res.DATA);
            // this.filterOptionList();
            this.orgList = res.DATA;
          } else {
            that.appService.toastTip(res.MSG, false);
          }
        });
      });
    } else {
      // 获取所有的组织架构数据
      this.dataService.getCorporatesChildrenById(-1).subscribe(res => {
        this.orgList = res.DATA;
        this.orgList = this.orgList.concat(res.DATA[0].childCorporates);
      });
    }
  }

  // 下一级
  nextLevel(org) {
    if (!this.isLoading) {
      this.orgCrumbs.push(org);
      this.getChildrenCorporate(org.id);
    }
  }

  // 关闭
  async close() {
    await this.modalController.dismiss();
  }

  // 确认
  async confirm() {
    await this.modalController.dismiss(this.selectedOrg);
    history.back();
  }

  async cancel() {
    await this.modalController.dismiss();
    history.back();
  }
}
