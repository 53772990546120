import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LocalStorageService } from './localstorage.service';
import { Observable } from 'rxjs/Rx';
import { Router } from '@angular/router';
import { resolve } from 'dns';
import { rejects } from 'assert';

const TIME_OUT = 60000;

@Injectable()
export class JobsEvaluationService {
    inspectionType;
    serviceType;
    employee;
    project;
    selectedFormOverview;
    selectedFormDetail;

    constructor(
        private http: HttpClient,
        public router: Router,
        public localStorageService: LocalStorageService) {
    }

    setData(para, data): any {
        this[para] = data;
    }

    getData(para): any {
        return this[para];
    }

    setMoreData(para, data): any  {
        para.forEach((element, index) => {
            this[element] = data[index];
        });
    }

    getMoreData(para): Promise<any> {
        const obj = {};
        para.forEach((element, index) => {
            obj[element] = this[element];
        });

        return new Promise((resolve, reject) => {
            obj ? resolve(obj) : reject(obj);
        });
        // return obj;
    }
}
