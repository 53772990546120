import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DataService, AppService, LocalStorageService } from '../../../service';
import { Router } from '@angular/router';
import { now } from 'moment';
import { ModalController, NavParams, NavController } from '@ionic/angular';
import * as dd from 'dingtalk-jsapi'; // 引入钉钉jsapi
import { debounceTime } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';

@Component({
  selector: 'app-lazy-loading-select',
  templateUrl: './lazy-loading-select.component.html',
  styleUrls: ['./lazy-loading-select.component.scss'],
})
export class LazyLoadingSelectComponent implements OnInit, AfterViewInit {
  pageTitle = '';
  searchMessage = '';
  placeholderMessage = '';
  originalOptionList = [];
  optionList = [];
  displayField: any;
  valueField = '';
  subContent = false;
  subDisplayField = '';
  multiSelect = false;
  needFilterSearch = true;
  needTranslation = false;
  selected: any;
  format = '';
  cancelText = '';
  confirmText = '';
  isStaff = false;
  isOrg = false;
  isSpace = false;
  isSpaceStaff = false;
  instantClose = false;
  spaceId = '';
  orgId = '';
  staffNumber = 50;
  staffSearchQuery = [];
  currentPage = 0;
  preventRepeatReuqest: boolean = false;
  staffTotal = 0;
  isNotifier = false;
  lastSearchTime = new Date().getTime();
  needShowColor = false;
  searchMes = new FormControl();
  isLoading = false;
  // 防抖
  timer = null;
  searchData = [];
  isAllOrg = false;
  selectedEmps;
  condition;

  @ViewChild("amtInput") amtInput: any;

  constructor(
    public dataService: DataService,
    public appService: AppService,
    public router: Router,
    public navCtrl: NavController,
    public navParams: NavParams,
    public modalController: ModalController,
    private changeDetectorRef: ChangeDetectorRef,
    public localStorageService: LocalStorageService) {
    let params = this.navParams.data;
    this.pageTitle = params.pageTitle ? params.pageTitle : '';
    this.placeholderMessage = params.placeholderMessage ? params.placeholderMessage : '';
    this.originalOptionList = params.optionList ? params.optionList : [];
    if (params.displayField instanceof Array) {
      this.displayField = params.displayField.length > 0 ? params.displayField : [];
    } else {
      this.displayField = params.displayField ? params.displayField : '';
    }
    this.valueField = params.valueField ? params.valueField : '';
    this.multiSelect = params.multiSelect ? params.multiSelect : false;
    if (params.needFilterSearch === false) {
      this.needFilterSearch = false;
    }
	  this.subContent = params.subContent ? params.subContent : false;
	  this.subDisplayField = params.subDisplayField ? params.subDisplayField : '';
    if (this.multiSelect) {
      this.selected = params.selected ? params.selected : [];
    } else {
      this.selected = params.selected ? params.selected : '';
    }
    this.needTranslation = params.needTranslation ? params.needTranslation : false;
    this.format = params.format ? params.format : '';
    this.cancelText = params.cancelText ? params.cancelText : 'aramark_cancel';
    this.confirmText = params.confirmText ? params.confirmText : 'aramark_confirm';
    this.condition = params.condition ? params.condition : null;

    this.isStaff = params.isStaff ? params.isStaff : false;
    this.isNotifier = params.isNotifier ? params.isNotifier : false;
    this.isOrg = params.isOrg ? params.isOrg : false;
  	this.isSpace = params.isSpace ? params.isSpace : false;
	  this.orgId = params.orgId ? params.orgId : false;
  	this.instantClose = params.instantClose ? params.instantClose : false;
    this.isAllOrg = params.isAllOrg ? params.isAllOrg : false;
    this.staffSearchQuery = params.searchQuery;
    this.searchData = params.searchData;
    this.selectedEmps = params.selectedEmps ? params.selectedEmps : null;
    this.needShowColor = params.needShowColor ? params.needShowColor : '';
    this.searchMes.valueChanges.subscribe((res) => {
      if (res) {
        this.currentPage = 0;
      }
    })
  }

  ngOnInit() {
    // 设置标题
    document.getElementsByTagName('title').item(0).innerText = this.pageTitle;
    if (dd.env.platform !== 'notInDingTalk') {
      dd.biz.navigation.setTitle({
        title: this.pageTitle // 钉钉设置导航栏标题
      });
    }
    // this.optionList = this.originalOptionList;
    // this.processDisplayValue(this.optionList);
    this.loaderMore();

    const that = this;
    window.onpopstate = function () {
      const a = document.getElementsByTagName('app-lazy-loading-select');
      if (a.length > 0) {
        setTimeout(() => {
          that.modalController.dismiss();
          // history.back();
        }, 100)
      }
    };
  }

  ngAfterViewInit() {
    // this.setFocus();
  }

  setFocus() {
    let that = this;
    setTimeout(() => {
        that.amtInput.setFocus();
    }, 150);
  }

  processSubMessage(message: string) {
	message = message ? message : '';
	const stringArr = message.split('/');
	if (stringArr.length < 2) {
		return message;
	}
	return stringArr[0] + '/.../' + stringArr[stringArr.length - 1];
  }

  markSelectedAndClose(item) {
	if (this.multiSelect) {
      this.selected = this.selected ? this.selected : [];
    } else {
      this.selected = this.selected ? this.selected : '';
    }

    if (!this.multiSelect) {
      if (this.valueField !== '$self') {
        this.selected = item[this.valueField];
      } else {
        this.selected = item;
      }
    } else {
      if (this.valueField !== '$self') {
        if (this.selected.indexOf(item[this.valueField]) === -1) {
          this.selected.push(item[this.valueField]);
        } else {
          this.selected.splice(this.selected.indexOf(item[this.valueField]), 1);
        }
      } else {
        if (this.selected.indexOf(item) === -1) {
          this.selected.push(item);
        } else {
          this.selected.splice(this.selected.indexOf(item), 1);
        }
      }
    }
    this.confirm();
  }

  markSelected(item) {
    if (this.multiSelect) {
      this.selected = this.selected ? this.selected : [];
    } else {
      this.selected = this.selected ? this.selected : '';
    }

    if (!this.multiSelect) {
      if (this.valueField !== '$self') {
        this.selected = item[this.valueField];
      } else {
        this.selected = item;
      }
    } else {
      if (this.valueField !== '$self') {
        if (this.selected.indexOf(item[this.valueField]) === -1) {
          this.selected.push(item[this.valueField]);
        } else {
          this.selected.splice(this.selected.indexOf(item[this.valueField]), 1);
        }
      } else {
		let index = this.selected.map(element => element.id).indexOf(item.id);
        if (index === -1) {
          this.selected.push(item);
        } else {
          this.selected.splice(index, 1);
        }
      }
    }
	if (!this.multiSelect && this.instantClose) {
		this.confirm();
	}
  }

  isSelected(item) {
    if (this.selected) {
      if (!this.multiSelect) {
        if (this.valueField !== '$self') {
          return this.selected === item[this.valueField];
        } else {
          return this.selected.id === item.id;
        }
      } else {
        if (this.valueField !== '$self') {
          return this.selected.indexOf(item[this.valueField]) !== -1;
        } else {
          return this.selected.some(element => element.id === item.id);
        }
      }
    }
    return false;
  }

  filterOptionList() {
    // const currenTime = new Date().getTime();
    // const a = currenTime - this.lastSearchTime;
    // console.log(a);
    // if (this.lastSearchTime && a < 8000) {
    // 	this.lastSearchTime = new Date().getTime();
    // 	return;
    // }

    // if (this.isStaff || this.isNotifier) { // 员工或通知对象
    if (this.searchMessage) {
      // 		let hasSearched = false;
      // 		if (this.staffSearchQuery) {
      // 			this.staffSearchQuery.forEach((res) => {
      // 				if (res.key === 'full_name') {
      // 					res.value = this.searchMessage;
      // 					hasSearched = true;
      // 				}
      // 			})
      // 		} else {
      // 			this.staffSearchQuery = [];
      // 		}

      // 		let query;
      // 		if (!hasSearched) {
      // 			const data = {
      // 				condition: "like",
      // 				connection: "and",
      // 				isValueADigital: false,
      // 				key: "full_name",
      // 				value: this.searchMessage
      // 			};
      // 			query = this.staffSearchQuery.concat(data);
      // 		} else {
      // 			query = this.staffSearchQuery;
      // 		}

      // 		this.currentPage = 0;
      // 		const param = {
      // 			pagingTool: {
      // 				currentPage: this.currentPage + 1,
      // 				pageSize: 50
      // 			},
      // 			// queryCriterias: [],
      // 			queryCriterias: query,
      // 			queryOrderBies: [{
      // 				columnName: 'code',
      // 				orderType: 'DESC'
      // 			}]
      // 		};
      // 		if (this.isStaff) {
      // 			const that = this;
      // 			const promise = new Promise<void>((resolve, reject) => {
      // 				that.dataService.getStaffList(param).subscribe(res => {
      // 					if (res && res.STATUS === 0) {
      // 						this.staffTotal = res.TOTAL_COUNT;
      // 						this.currentPage ++;
      // 						this.optionList = res.DATA;
      // 						this.processDisplayValue(this.optionList);

      // 						resolve();
      // 					} else {
      // 						that.appService.toastTip(res.MSG, false);
      // 						reject();
      // 					}
      // 				});
      // 			});
      // 			return promise;
      // 		} else if (this.isNotifier) {
      // 			const that = this;
      // 			that.dataService.getEmployeesDivisionalList(param).then(res => {
      // 				if (res && res.STATUS === 0) {
      // 					res.DATA.forEach(v => {
      // 						v.tempOrgName = v.orgs && v.orgs.length ? v.orgs[0]['full_name'] : '';
      // 					});
      // 					this.staffTotal = res.TOTAL_COUNT;
      // 					this.currentPage ++;
      // 					this.optionList = res.DATA;
      // 					this.processDisplayValue(this.optionList);

      // 				} else {
      // 					that.appService.toastTip(res.MSG, false);
      // 				}
      // 			});
      // 		}


      // 	} else {
      // 		this.optionList = this.originalOptionList;
      // 		this.processDisplayValue(this.optionList);
      // 	}
      // } else { // 非员工
      // 	if (this.searchMessage) {
      let filteredList = [];
      if (this.displayField instanceof Array) {
        filteredList = this.originalOptionList.filter(item => {
          let filterRawInfo = '';
          this.displayField.forEach(field => {
            filterRawInfo += item[field];
          });
          return filterRawInfo.indexOf(this.searchMessage) !== -1;
        });
      } else {
        if (this.displayField == 'spaceName') {
          filteredList = this.originalOptionList;
        } else {
          filteredList = this.originalOptionList.filter(item => item[this.displayField].indexOf(this.searchMessage) !== -1);
        }
      }
      this.optionList = filteredList;
    } else {
      this.optionList = this.originalOptionList;
      this.changeDetectorRef.markForCheck();
      this.changeDetectorRef.detectChanges();
    }
    this.processDisplayValue(this.optionList);
    // }
  }

  async cancel() {
    await this.modalController.dismiss();
    history.back();
  }

  async confirm() {
    if (this.selected instanceof Array) {
      this.selected.forEach(item => {
        if (item.hasOwnProperty('finalDisplay')) {
          delete item.finalDisplay;
        }
      });
    } else {
      if (this.selected.hasOwnProperty('finalDisplay')) {
        delete this.selected.finalDisplay;
      }
    }

    await this.modalController.dismiss(this.selected);
    history.back();
  }

  processDisplayValue(list: any[]) {
    if (this.displayField instanceof Array) {
      if (this.format) {
        list.forEach(item => {
          item.finalDisplay = this.format;
          this.displayField.forEach((field, index) => {
            item.finalDisplay = item.finalDisplay.replace(('$' + (index + 1)), item[field]);
          });
        });
      }
    } else {
      list.forEach(item => {
        item.finalDisplay = item[this.displayField];
      });
    }
  }

  loaderMore(event?: any) {
    const that = this;
    clearTimeout(that.timer) // 每次进来的时候都将之前的清除掉，如果还没到一秒的时候就将之前的清除掉，这样就不会触发之前setTimeout绑定的事件， 如果超过一秒，之前的事件就会被触发下次进来的时候同样清除之前的timer
    that.timer = setTimeout(function () {
      // 在这里进行我们的操作  这样就不会频繁的进行我们这里面的操作了

      // 显示loading页面
      this.isLoading = true;

      if (event) {
        // if (that.staffTotal > that.originalOptionList.length) {
        if (that.isStaff) {
          that.getStaffData(event);
        } else if (that.isNotifier) {
          that.getNotifierfData(event);
        } else if (that.isOrg) {
          that.getOrgData(event);
        } else if (that.isSpace && that.orgId) {
          that.getSpaceData(event);
        } else if (that.isSpaceStaff && that.spaceId && that.orgId) {
          that.getSpaceStaffData(event);
        } else if (that.condition) {
          that.getEmpCondition(event);
        }
        // } else {
        //   event.target.complete();
        // }
      } else {
        if (that.isStaff) {
          that.getStaffData(event, 'search');
        } else if (that.isNotifier) {
          that.getNotifierfData(event, 'search');
        } else if (that.isOrg) {
          that.getOrgData(event, 'search');
        } else if (that.isSpace && that.orgId) {
          that.getSpaceData(event, 'search');
        } else if (that.isSpaceStaff && that.spaceId && that.orgId) {
          that.getSpaceStaffData(event, 'search');
        } else if (that.condition) {
          that.getEmpCondition(event, 'search');
        }
      }
    }, 500);
  }

  async getStaffData(event?, flag?): Promise<any> {
    // const param = {
    //   pagingTool: {
    //     currentPage: this.currentPage + 1,
    //     pageSize: 20
    //   },
    //   queryCriterias: this.staffSearchQuery,
    //   queryOrderBies: [{
    //     columnName: 'code',
    //     orderType: 'DESC'
    //   }]
    // };
    let param;
    if (!flag) {
      // if (this.staffSearchQuery.length >) {
      //   searchData
      // }
      const data = this.staffSearchQuery.concat(this.searchData);
      param = {
        pagingTool: {
          currentPage: this.currentPage + 1,
          pageSize: 20
        },
        queryCriterias: data,
        queryOrderBies: [{
          columnName: 'code',
          orderType: 'asc'
        }]
      };
    } else {
      this.staffSearchQuery = [{
        condition: "like",
        connection: "and",
        isValueADigital: false,
        key: "code",
        value: this.searchMessage,
      }];
      const data = this.staffSearchQuery.concat(this.searchData);

      param = {
        pagingTool: {
          currentPage: 1,
          pageSize: 20
        },
        queryCriterias: data,
        queryOrderBies: [{
          columnName: 'code',
          orderType: 'asc'
        }]
      };

      param.queryCriterias.forEach(element => {
        if (element.key == 'short_name') {
          element.value = this.searchMessage;
        }
      });
    }

    if (this.selectedEmps) {
      param.queryCriterias.push({
        condition: "in",
        connection: "and",
        isValueADigital: false,
        key: "selectedEmps",
        value: this.selectedEmps,
      })
    }

    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      that.dataService.getStaffList(param).subscribe(res => {
        if (res && res.STATUS === 0) {
          this.isLoading = false;

          if (event) {
            event.target.complete();
          }
          this.currentPage++;

          if (!flag) {
            this.originalOptionList = this.originalOptionList.concat(res.DATA);
          } else {
            this.originalOptionList = res.DATA;
          }
          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
          this.filterOptionList();
          resolve();
        } else {
          that.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

	async getSpaceStaffData(event?, flag?): Promise<any> {
		let param;
		if (!flag) {
			const data = this.staffSearchQuery.concat(this.searchData);
			param = {
				pagingTool: {
					currentPage: this.currentPage + 1,
					pageSize: 20
				},
				queryCriterias: data,
				queryOrderBies: [{
					columnName: 'code',
					orderType: 'asc'
				}]
			};
		} else {
			this.staffSearchQuery = [{
				condition: "like",
				connection: "and",
				isValueADigital: false,
				key: "empInfo",
				value: this.searchMessage,
			}];
			const data = this.staffSearchQuery.concat(this.searchData);
			param = {
				pagingTool: {
					currentPage: 1,
					pageSize: 20
				},
				queryCriterias: data,
				queryOrderBies: [{
					columnName: 'code',
					orderType: 'asc'
				}]
			};
		}
		
		const that = this;
		const promise = new Promise<void>((resolve, reject) => {
			that.dataService.getEmployeeByOrgIdAndSpaceId(param, this.orgId, this.spaceId).subscribe(res => {
				if (res && res.STATUS === 0) {
					this.isLoading = false;

					if (event) {
						event.target.complete();
					}
					this.currentPage++;

					if (!flag) {
						this.originalOptionList = this.originalOptionList.concat(res.DATA);
					} else {
						this.originalOptionList = res.DATA;
					}
					this.changeDetectorRef.markForCheck();
					this.changeDetectorRef.detectChanges();
					this.filterOptionList();
					resolve();
				} else {
					that.appService.toastTip(res.MSG, false);
					reject();
				}
			});
		});
		return promise;
	}

	async getSpaceData(event?, flag?): Promise<any> {
		let param;
		if (!flag) {
			const data = this.staffSearchQuery.concat(this.searchData);
      data.push({
				condition: 'in',
				connection: 'and',
				key: 'org_id',
				value: this.orgId
			});
			param = {
				pagingTool: {
					currentPage: this.currentPage + 1,
					pageSize: 20
				},
				queryCriterias: data,
				queryOrderBies: [{
					columnName: 'id',
					orderType: 'desc'
				}]
			};
		} else {
			this.staffSearchQuery = [{
				condition: "like",
				connection: "and",
				isValueADigital: false,
				key: "name",
				value: this.searchMessage,
			}];
			const data = this.staffSearchQuery.concat(this.searchData);
			data.push({
				condition: 'in',
				connection: 'and',
				key: 'org_id',
				value: this.orgId
			});
			param = {
				pagingTool: {
					currentPage: 1,
					pageSize: 20
				},
				queryCriterias: data,
				queryOrderBies: [{
					columnName: 'id',
					orderType: 'desc'
				}]
			};
		}

		const that = this;
		const promise = new Promise<void>((resolve, reject) => {
			that.dataService.getSpaceList(param).subscribe(res => {
				if (res && res.STATUS === 0) {
					this.isLoading = false;

					if (event) {
						event.target.complete();
					}
					this.currentPage++;

					if (!flag) {
						this.originalOptionList = this.originalOptionList.concat(res.DATA);
					} else {
						this.originalOptionList = res.DATA;
					}
					this.changeDetectorRef.markForCheck();
					this.changeDetectorRef.detectChanges();
					this.filterOptionList();
					resolve();
				} else {
					that.appService.toastTip(res.MSG, false);
					reject();
				}
			});
		});
		return promise;
	}

  async getNotifierfData(event?, flag?): Promise<any> {
    // const param = {
    //   pagingTool: {
    //     currentPage: this.currentPage + 1,
    //     pageSize: 20
    //   },
    //   queryCriterias: this.staffSearchQuery,
    //   queryOrderBies: [{
    //     columnName: 'code',
    //     orderType: 'DESC'
    //   }]
    // };

    let param;
    if (!flag) {
      param = {
        pagingTool: {
          currentPage: this.currentPage + 1,
          pageSize: 20
        },
        queryCriterias: this.staffSearchQuery,
        queryOrderBies: [{
          columnName: 'code',
          orderType: 'asc'
        }]
      };
    } else {
      this.staffSearchQuery = [{
        condition: "like",
        connection: "and",
        isValueADigital: false,
        key: "code",
        value: this.searchMessage,
      }];
      param = {
        pagingTool: {
          currentPage: 1,
          pageSize: 20
        },
        queryCriterias: this.staffSearchQuery,
        queryOrderBies: [{
          columnName: 'code',
          orderType: 'asc'
        }]
      };

      param.queryCriterias.forEach(element => {
        if (element.key == 'short_name') {
          element.value = this.searchMessage;
        }
      });
    }


    const that = this;
    that.dataService.getEmployeesDivisionalList(param).then(res => {
      if (res && res.STATUS === 0) {
        this.isLoading = false;

        if (event) {
          event.target.complete();
        }
        res.DATA.forEach(v => {
          v.tempOrgName = v.orgs && v.orgs.length ? v.orgs[0]['full_name'] : '';
        });
        this.currentPage++;
        if (!flag) {
          this.originalOptionList = this.originalOptionList.concat(res.DATA);
        } else {
          this.originalOptionList = res.DATA;
        }
        this.changeDetectorRef.markForCheck();
        this.changeDetectorRef.detectChanges();
        this.filterOptionList();
      } else {
        that.appService.toastTip(res.MSG, false);
      }
    });
  }

  async getOrgData(event?, flag?): Promise<any> {
    let param;
    if (!flag) {
      let data;
      if (this.searchData) {
        data = this.staffSearchQuery.concat(this.searchData);
      } else {
        data = this.staffSearchQuery;
      }

      param = {
        pagingTool: {
          currentPage: this.currentPage + 1,
          pageSize: 20
        },
        queryCriterias: data,
        queryOrderBies: []
      };
    } else {
      this.staffSearchQuery = [{
        condition: "like",
        connection: "and",
        isValueADigital: false,
        key: "code",
        value: this.searchMessage,
      }];

      let data;
      if (this.searchData) {
        data = this.staffSearchQuery.concat(this.searchData);
      } else {
        data = this.staffSearchQuery;
      }

      param = {
        pagingTool: {
          currentPage: 1,
          pageSize: 20
        },
        queryCriterias: data,
        queryOrderBies: []
      };

      // if (this.searchMessage) {
      param.queryCriterias.forEach(element => {
        if (element && element.key == 'short_name') {
          element.value = this.searchMessage;
        }
      });
      // }
    }

    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      let apiStr = '';
      if (this.isAllOrg) {
        apiStr = 'getProjectCostCenterCodeList';
      } else {
        apiStr = 'getProjectCostCenterCodeListTable';
      }
      that.dataService[apiStr](param).subscribe(res => {
        if (res && res.STATUS === 0) {
          this.isLoading = false;

          if (event) {
            event.target.complete();
          }
          this.currentPage++;
          if (!flag) {
            this.originalOptionList = this.originalOptionList.concat(res.DATA);
          } else {
            this.originalOptionList = res.DATA;
          }
          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
          this.filterOptionList();
          resolve();
        } else {
          that.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }

  getEmpCondition(event?, flag?) {
    let param;
    if (!flag) {

      const data = this.staffSearchQuery.concat(this.searchData);
      param = {
        pagingTool: {
          currentPage: this.currentPage + 1,
          pageSize: 20
        },
        queryCriterias: data,
        queryOrderBies: [{
          columnName: 'code',
          orderType: 'asc'
        }]
      };
    } else {
      this.staffSearchQuery = [{
        condition: "like",
        connection: "and",
        isValueADigital: false,
        key: "code",
        value: this.searchMessage,
      }];
      const data = this.staffSearchQuery.concat(this.searchData);

      param = {
        pagingTool: {
          currentPage: 1,
          pageSize: 20
        },
        queryCriterias: data,
        queryOrderBies: [{
          columnName: 'code',
          orderType: 'asc'
        }]
      };

      param.queryCriterias.forEach(element => {
        if (element.key == 'short_name') {
          element.value = this.searchMessage;
        }
      });
    }

    if (this.selectedEmps) {
      param.queryCriterias.push({
        condition: "in",
        connection: "and",
        isValueADigital: false,
        key: "selectedEmps",
        value: this.selectedEmps,
      })
    }

    const that = this;
    const promise = new Promise<void>((resolve, reject) => {
      that.dataService.getStaffListCondition(param).subscribe(res => {
        if (res && res.STATUS === 0) {
          this.isLoading = false;

          if (event) {
            event.target.complete();
          }
          this.currentPage++;

          if (!flag) {
            this.originalOptionList = this.originalOptionList.concat(res.DATA);
          } else {
            this.originalOptionList = res.DATA;
          }
          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
          this.filterOptionList();
          resolve();
        } else {
          that.appService.toastTip(res.MSG, false);
          reject();
        }
      });
    });
    return promise;
  }
}
