import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// export default vConsole
// window.console.log = function () { };
platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));

// window.alert = function () {
//   return false;
// }
// window.console.log = function() {};
