/*
 * @Author: Xu Xingshan 
 * @Date: 2019-03-20 23:10:41 
 * @Last Modified by: Xu Xingshan
 * @Last Modified time: 2019-09-26 23:30:17
 */
 import { Injectable } from '@angular/core';
 import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
 import { LocalStorageService } from '../service/localstorage.service';
 import { Observable, Subject } from 'rxjs/Rx';
 import { Router } from '@angular/router';
 import { resolve } from 'dns';
 import { rejects } from 'assert';
 import { of } from 'rxjs';
 import { QueryCriteria } from '../models/query-criteria.model';

 const TIME_OUT = 60000;
 @Injectable()
 export class DataService {
  public pageSize = 10;
  public initBasic = true;
  public pendding = 0;
  public todo = 0;
  public urlPrefix = this.localStorageService.getStore('API_DOMAIN') + this.localStorageService.getStore('API_DIR');
  public refresh = false;

  public returnSwipe = new Subject<any>();
  returnSwipeObservable = this.returnSwipe.asObservable();

  constructor(
    private http: HttpClient,
    public router: Router,
    public localStorageService: LocalStorageService) {
  }
  updatePrefix() {
    this.urlPrefix = this.localStorageService.getStore('API_DOMAIN') + this.localStorageService.getStore('API_DIR');
  }
  // 封装POST
  post(url, para, isAuth) {
    let headers = new HttpHeaders();
    const isMedical = url.indexOf('medicalSatisfactionInspection') !== -1 ? true : false;
    if (isAuth && !isMedical) {
      headers = new HttpHeaders().set('token', this.localStorageService.getStore('token'));
    }
    let fullUrl = this.urlPrefix + url;
    return this.http.post(fullUrl, para, { headers: headers })
    .timeout(TIME_OUT)
    .catch((err: Response) => {
      if (err.status == 401 || err.status === undefined) {
        this.localStorageService.removeStore('token')
          //  this.appService.toastTip('get_data_failed_key',true);
          this.router.navigate(['/login']);
        }
        //  let errDetail = err.eror;
        console.log('errDetail', fullUrl);
        return Observable.throw(new Error(err.toString()));
      })
  }
  // 封装PUT
  put(url, para, isAuth) {
    let headers = new HttpHeaders();
    if (isAuth) {
      headers = new HttpHeaders().set('token', this.localStorageService.getStore('token'));
    }
    let fullUrl = this.urlPrefix + url;
    return this.http.put(fullUrl, para, { headers: headers })
    .timeout(TIME_OUT)
    .catch((err: Response) => {
      if (err.status == 401 || err.status === undefined) {
        this.localStorageService.removeStore('token')
          //  this.appService.toastTip('get_data_failed_key',true);
          //  this.router.navigateByUrl('/login');
          this.router.navigate(['/login']);

        }
        //  let errDetail = err.eror;
        //  console.log(errDetail);
        return Observable.throw(new Error(err.toString()));
      })
  }
  // 封装DELETE
  delete(url, isAuth) {
    let headers = new HttpHeaders();
    if (isAuth) {
      headers = new HttpHeaders().set('token', this.localStorageService.getStore('token'));
    }
    let fullUrl = this.urlPrefix + url;
    return this.http.delete(fullUrl, { headers: headers })
    .timeout(TIME_OUT)
    .catch((err: Response) => {
      if (err.status === 401 || err.status === undefined) {
        this.localStorageService.removeStore('token')
          //  this.appService.toastTip('get_data_failed_key',true);
          //  this.router.navigateByUrl('/login');
          this.router.navigate(['/login']);

        }
        //  let errDetail = err.eror;
        //  console.log(errDetail);
        return Observable.throw(new Error(err.toString()));
      })
  }
  // 封装GET
  get(url, isAuth) {
    const isMedical = url.indexOf('medicalSatisfactionInspection') !== -1 ? true : false;

    let headers = new HttpHeaders();
    if (isAuth && !isMedical) {
      headers = new HttpHeaders().set('token', this.localStorageService.getStore('token'));
    }
    let fullUrl = this.urlPrefix + url;
    return this.http.get(fullUrl, { headers: headers })
    .timeout(TIME_OUT)
    .catch((err: Response) => {
      if (err.status == 401 || err.status === undefined) {
        this.localStorageService.removeStore('token')
          //  this.appService.toastTip('get_data_failed_key',true);
          //  this.router.navigateByUrl('/login');
          this.router.navigate(['/login']);

        }
        //  let errDetail = err.eror;
        //  console.log(errDetail);
        return Observable.throw(new Error(err.toString()));
      })
    //  .do(
    //    data => {
    //      console.log('data')
    //      console.log( Response.toString());
    //      console.log(data)
    //    }
    //  )

  }
  // 封装带入URL的GET，目前用于验证APP输入服务器端URL正确性
  getWithUrl(url, isAuth) {
    let headers = new HttpHeaders();
    if (isAuth) {
      headers = new HttpHeaders().set('token', this.localStorageService.getStore('token'));
    }
    let fullUrl = url;
    return this.http.get(fullUrl, { headers: headers })
    .timeout(TIME_OUT)
    .catch((err: Response) => {
      if (err.status == 401 || err.status === undefined) {
        this.localStorageService.removeStore('token')
          //  this.appService.toastTip('get_data_failed_key',true);
          //  this.router.navigateByUrl('/login');
          this.router.navigate(['/login']);

        }
        //  let errDetail = err.eror;
        //  console.log(errDetail);
        return Observable.throw(new Error(err.toString()));
      })
    //  .do(
    //    data => {
    //      console.log('data')
    //      console.log( Response.toString());
    //      console.log(data)
    //    }
    //  )

  }
  // 验证APP输入服务器端URL正确性
  systemInformationVersion(url: string): any {
    //  const params = new HttpParams()
    //  .set('codes', codes)
    return this.getWithUrl(url + '/system-information/version', false);
  }
  checkVersion(para): any {
    return this.get('/configurations/' + para, false);
  }
  // 登录
  permissionsLogin(userName: string, password: string, verificationCode: string, proof: string): any {
    return this.post('/permissions/login', { userName, password, verificationCode, proof }, false);
  }
  getVerificationCode(): any {
    return this.get('/permissions/getVerifyCode', false);
  }
  // 查找Code的子Code
  codesChildrenOf(codes): any {
    //  const params = new HttpParams()
    //  .set('codes', codes)
    return this.get('/codes/childrenOf?codes=' + codes, true);
  }
  // 使用Like查找Code列表
  codesAllList(codeLike): any {
    //  const params = new HttpParams()
    //  .set('codes', codes)
    var obj = {
      'pagingTool':
      {
        'currentPage': 0, 'pageSize': 1000
      },
      'queryCriterias':
      [
      {
        'connection': 'and', 'key': 'code', 'condition': 'like', 'value': codeLike, 'isValueADigital': false
      }
      ]
    };
    return this.post('/codes/all/list', obj, true);
  }

  getCalendarInfo(para): any {
    return this.post(`/training/classes/app/calendar?year=${para.year}&month=${para.month}`, {}, true);
  }

  getCalendarIon(date) {
    let parame =  {
      'pagingTool': {
        'currentPage': 1,
        'pageSize': 10000
      },
      'queryCriterias': [{
        'connection': 'and',
        'key': 'end_dtm',
        'condition': '<=',
        'value': '2021-03-13T15:59:00.000Z',
        'isValueADigital': false
      }]
    };
    // return this.post('/training/trainRecords/calendar', parame, true);
    return this.post('/employees/autocompletelist', parame, true);
  }

  getCalendarDetailed(para): any {
    return this.post(`/training/classes/app/calendar/detail?year=${para.year}&month=${para.month}`, {}, true);
  }

  downCorporateByUser(): any {
    let para = {
      'pagingTool': {
        'currentPage': 1,
        'pageSize': 100
      }
    };
    return this.post('/corporates/downCorporateByUser', para, true);
  }

  // 公告列表
  announcementListUser(para): any {
    return this.post('/announcement/list', para, true);
  }
  // 设置公告已读
  announcementRead(para): any {
    return this.put('/announcement/read', para, true);
  }
  // 消息列表
  messageListSinotrans(para): any {
    return this.post('/message/list/sinotrans', para, true);
  }
  //消息列表 2）
  messageList(para): any {
    return this.post('/message/list', para, true);
  }
  //更新消息已读
  updateMessageRead(para): any {
    return this.get('/message/read/' + para, true);
  }
  //设置消息已读
  updateMessage(para): any {
    return this.put('/message', para, true);
  }

  //设置消息已读 -新
  updateReadMessage(ids): any {
    return this.get('/message/read/' + ids, true);
  }

  readAllMessage(type): any {
    return this.get(type ? '/message/readAll?type=' + type : '/message/readAll', true);
  }

  getMessageDetail(id): any {
    return this.get('/message/' + id, true);
  }

  //获取未读消息数
  getNewMessageCount(): any {
    return this.get('/message/NewMessageNumber?type=_SYS_MESSAGE_A', true);
  }
  //获取未读通知数
  getNewNotificationCount(): any {
    return this.get('/message/NewMessageNumber', true);
  }
  // 任务中心列表(用于待办和历史)
  taskList(para): any {
    return this.post('/tasks/list', para, true);
  }
  // 任务中心列表(用于待定任务)
  penddingTaskofMine(): any {
    return this.get('/inspections/pendingTaskOfMine', true);
  }
  // 执行检查详情1,复审检查详情1
  // id, =>taskList().id||penddingTaskofMine().id
  inspectionsTask(id): any {
    return this.get('/inspections/task/' + id, true);
  }
  // 执行检查详情2,复审检查详情2
  // activity_id,=>inspectionsTask().task_detail.activity_id
  activityById(activity_id): any {
    return this.get('/activities/' + activity_id, true);
  }
  activitiesInitData(): any {
    return this.get('/activities/initData', true);
  }
  getInspectionsObject(id): any {
    return this.get('/inspections/object/' + id, true);
  }
  // 添加执行检查对象
  addInspectionsObject(para, reference_id): any {
    return this.post('/inspections/' + reference_id + '/object', para, true);
  }
  // 修改执行检查对象
  updateInspectionsObject(para): any {
    return this.put('/inspections/object', para, true);
  }

  // 删除执行检查对象
  deleteInspectionsObject(id): any {
    return this.delete('/inspections/object/?id=' + id, true);
  }
  // 获取执行检查对象列表
  // reference_id,=>inspectionsTask().reference_id
  inspectionsObjectList(reference_id): any {
    var obj = {
      'pagingTool': {
        'currentPage': 1,
        'pageSize': 1000
      }
    };
    return this.post('/inspections/' + reference_id + '/object/list', obj, true);
  }
  inspectionsObjectFormsById(id): any {
    return this.get('/inspections/object/' + id + '/forms', true);
  }

  inspectionsObjectBasicInfo(inspectionId, formId): any {
    return this.get('/inspections/object/' + inspectionId + '/form/' + formId + '/basicInfo', true);
  }
  addInspectionsObjectBasicInfo(inspectionId, formId, para): any {
    //  var para = {'other_examiner':null,'inspected_date':'2019-04-22T22:38:00.000Z'}
    return this.post('/inspections/object/' + inspectionId + '/form/' + formId + '/basicInfo', para, true);
  }
  // 修改执行检查对象
  updateInspectionsObjectBasicInfo(inspectionId, formId, para): any {
    //  var para = {'id':'96','inspected_date':'2019-04-21T14:49:00.000Z','other_examiner':null,'comment':null,'form_response_masterId':'4074','status':'_SYS_B7_1'}
    return this.put('/inspections/object/' + inspectionId + '/form/' + formId + '/basicInfo', para, true);
  }

  inspectionsObjectResponse(inspectionId, formId): any {
    return this.get('/inspections/object/' + inspectionId + '/form/' + formId + '/response', true);
  }

  addInspectionsObjectResponse(inspectionId, formId, para): any {
    //  var para ={'responseList':[{'severity':'_SYS_B9_5','comment':'23','immediate_action':'23','form_element_id':'803','scene_pictures':''},{'severity':'_SYS_B9_5','comment':'23','immediate_action':'23','form_element_id':'804','scene_pictures':''},{'severity':'_SYS_B9_5','comment':'23','immediate_action':'23','form_element_id':'805','scene_pictures':''},{'severity':'_SYS_B9_5','response':'_SYS_AM_1','form_element_id':'840','scene_pictures':''}],'isComplete':false}
    return this.post('/inspections/object/' + inspectionId + '/form/' + formId + '/response', para, true);
  }

  updateInspectionsObjectResponse(inspectionId, formId, para): any {
    //  var para ={'responseList':[{'id':'5636','form_element_id':'803','response':null,'severity':'_SYS_B9_5','scene_pictures':'','immediate_action':null,'deviation_id':'2031','comment':null,'implication':null,'onDemandComment':null},{'id':'5637','form_element_id':'804','response':null,'severity':'_SYS_B9_5','scene_pictures':'','immediate_action':null,'deviation_id':'2032','comment':null,'implication':null,'onDemandComment':null},{'id':'5638','form_element_id':'805','response':null,'severity':'_SYS_B9_5','scene_pictures':'','immediate_action':null,'deviation_id':'2033','comment':null,'implication':null,'onDemandComment':null},{'id':'5639','form_element_id':'840','response':null,'severity':'_SYS_B9_5','scene_pictures':'','immediate_action':null,'deviation_id':'2034','comment':null,'implication':null,'onDemandComment':null}],'isComplete':false};
    return this.put('/inspections/object/' + inspectionId + '/form/' + formId + '/response', para, true);
  }
  correctiveActionList(deviation_id): any {
    let para = { 'pagingTool': { 'currentPage': 1, 'pageSize': 1000 }, 'additionalParams': { 'deviation_type': '_SYS_B1_2' } };
    return this.post('/deviations/' + deviation_id + '/correctiveAction/list', para, true);
  }
  correctiveActionListNoRestr(deviation_id): any {
    let para = { 'pagingTool': { 'currentPage': 1, 'pageSize': 1000 } };
    return this.post('/deviations/' + deviation_id + '/correctiveAction/list', para, true);
  }

  getProjectManagers(orgId): any {
    let para = { 
      'pagingTool': { 'currentPage': 1, 'pageSize': 10 },
      'queryCriterias': [
      {
        'connection': 'and', 'key': 'org_ids', 'condition': '=', 'value': orgId, 'isValueADigital': false
      },
      {
        'connection': 'and', 'key': 'titles', 'condition': '=', 'value': '_SYS_CB_15', 'isValueADigital': false
      }
      ]
    };
    return this.post('/employees/autocompletelist/titles', para, true);
  }
  
  getProjectManagersTitle(params): any {
    return this.post('/employees/autocompletelist/titles', params, true);
  }

  //  http:// sinotrans-dev.usequantum.com.cn/facts_backend-2.6/rest/deviations/2333/correctiveAction/list
  // regulation_id,=>inspectionsTask().regulation_id
  regulationById(regulation_id): any {
    return this.get('/regulations/' + regulation_id, true);
  }
  regulationsInitData(): any {
    return this.get('/regulations/initData', true);
  }
  // reference_id,=>inspectionsTask().reference_id
  physicalStructureInspectionAudit(reference_id): any {
    return this.get('/physicalStructure/list/inspection_audit/' + reference_id, true);
  }
  // 获取需要隐藏的建筑物类型,建筑物类型Code _SYS_AA
  inspectionObjectHideType(): any {
    return this.get('/configurations/inspection_object_hide_type', true);
  }
  // 获取建筑物
  // reference_id,=>inspectionsTask().reference_id
  corporatesDepartment(reference_id): any {
    return this.get('/corporates/department/' + reference_id, true);
  }
  informationColumnsGetColumn(para): any {
    //  para = {
    //    lang: 'zh_CN'
    //    title: 'inspection_object_table'
    //    url: '/inspection/inspection-object'
    //  }
    return this.post('/information/columns/getColumn', para, true);
  }
  // 接受任务
  acceptTask(taskId, userId): any {
    return this.put('/tasks/' + taskId + '/to/' + userId, null, true);
  }
  // 开始任务
  startTask(taskId): any {
    return this.put('/tasks/start/' + taskId, null, true);
  }
  // 获取任务表单详情
  formsIdPreview(para): any {
    return this.get('forms/' + para + '/preview', true);
  }

  // 获取当前用户menus list
  getUserMenusList() {
    return this.get('/menus/list', true);
    // const a = {
    //   DATA: []
    // };
    // a.DATA = [
    //   'add.report', 'add.report.hidden', 'add.report.immediatelyCheck', 'recordList',
    //   'recordList.hidden', 'recordList.hidden.list', 'recordList.hidden.rectificationList',
    //   'recordList.check', 'recordList.check.list', 'recordList.check.rectificationList'
    // ];
    // return of(a);
  }

  // 扫码功能跳转
  getBarcodeScanner(code) {
    return this.get('/space/getBySpaceCode/' + code, true);
  }

  // 员工列表
  //  employeesList(): any {
  //    var obj = {
  //      'pagingTool': {
  //        'currentPage': 1,
  //        'pageSize': 1000
  //      }
  //    };
  //    return this.post('/employees/list',obj, true);
  //  }
  employeesListAll(): any {
    return this.post('/employees/list/all', null, true);
  }

  employeesList(para): any {
    return this.post('/employees/list', para, true);
  }

  usersDeviationAndQuery(id): any {
    let para = { 'pagingTool': { 'currentPage': 1, 'pageSize': 500 }, 'queryOrderBies': [{ 'columnName': 'login_id', 'orderType': 'desc' }] }
    return this.post('/users/deviationAndQuery/' + id, para, true);
  }
  corporatesDeviation(id): any {
    return this.get('/corporates/deviation/' + id, true);
  }
  inspectionsComplete(para, id): any {
    return this.post('/inspections/complete/' + id, para, true);
  }
  // ############执行整改措施############
  actionTypeDisplay(): any {
    return this.get('/configurations/corrective_action_type_display', true);
  }
  // 执行整改措施详情
  correctiveActionsTask(id): any {
    return this.get('/correctiveActions/task/' + id, true);
  }
  // 执行整改不符合项详情
  deviationsTask(id): any {
    return this.get('/deviations/task/' + id, true);
  }
  // 获取公司信息
  corporateById(department): any {
    return this.get('/corporates/' + department, true);
  }
  correctiveResultId(reference_id): any {
    return this.get('/correctiveActionVerifications/correctiveResultId/' + reference_id, true);
  }
  // 获取编辑完成信息
  correctiveActionsResult(reference_id): any {
    return this.get('/correctiveActions/result/' + reference_id, true);
  }
  // 添加编辑完成信息
  addCorrectiveActionsResult(para, reference_id): any {
    return this.post('/correctiveActions/result/' + reference_id, para, true);
  }
  getCorrectiveActionsWithCorrectiveActionId(id): any {
    return this.get('/correctiveActions/' + id, true);
  }
  // 修改编辑完成信息
  updateCorrectiveActionsResult(para): any {
    return this.put('/correctiveActions/result', para, true);
  }

  usersNoValidate(user_id): any {
    return this.get('/users/' + user_id + '/noValidate', true);
  }
  // 查看执行验证历史
  correctiveActionsHistory(id): any {
    return this.get('/correctiveActions/history/' + id, true);
  }
  // 修改完成信息
  updateDeviationCorrectiveAction(para, deviation_id): any {
    return this.put('/correctiveActions/deviation/' + deviation_id + '/correctiveAction', para, true);
  }
  // 添加完成信息
  addDeviationCorrectiveAction(para, deviation_id): any {
    return this.post('/correctiveActions/deviation/' + deviation_id + '/correctiveAction', para, true);
  }
  // 完成整改措施
  correctiveActionsComplete(para, id): any {
    return this.post('/correctiveActions/complete/' + id, para, true);
  }
  // 完成验证整改措施
  correctiveActionVerificationsComplete(para, id): any {
    return this.post('/correctiveActionVerifications/complete/' + id, para, true);
  }
  // 保存检验整改措施
  correctiveActionVerificationsVerification(para): any {
    return this.put('/correctiveActionVerifications/verification/', para, true);
  }

  // 获取检验整改措施信息
  correctiveActionVerificationsTask(id): any {
    return this.get('/correctiveActionVerifications/task/' + id, true);
  }
  // 获取检验整改措施信息*
  correctiveActionVerifications(id): any {
    return this.get('/correctiveActionVerifications/' + id, true);
  }
  // 获取巡检工厂列表
  facilitiesUserId(): any {
    return this.get('/facilities/userId', true);
  }
  // 巡检保存信息
  inspectionsFindingBasicInfo(para): any {
    return this.post('/inspections/finding/basicInfo', para, true);
  }

  updateInspectionsFindingBasicInfo(para, id): any {
    return this.post('/inspections/' + id + '/finding/basicInfo', para, true);
  }

  // 巡检获取基本信息
  inspectionsGetInspectionById(id): any {
    return this.get('/inspections/getInspectionById/' + id, true);
  }

  // 巡检获取检查列表
  inspectionsFindingList(id): any {
    return this.get('/inspections/' + id + '/finding/list', true);
  }

  inspectionsReport(id): any {
    const para = {};
    return this.post('/inspections/' + id + '/report', para, true);
  }

  physicalStructureLocation(id): any {
    return this.post('/physicalStructure/location/' + id, null, true);
  }
  // 添加发现的问题
  inspectionsFindingById(para, id): any {
    return this.post('/inspections/' + id + '/finding', para, true);
  }
  // 修改发现的问题
  inspectionsFinding(para): any {
    return this.put('/inspections/finding', para, true);
  }
  deleteInspectionsFinding(id) {
    return this.delete('/inspections/finding?id=' + id, true);
  }
  // 查找组织架构人员
  usersPhysicalAndQuery(id): any {
    var para = { 'pagingTool': { 'currentPage': 1, 'pageSize': 50 }, 'queryOrderBies': [{ 'columnName': 'login_id', 'orderType': 'desc' }] }
    return this.post('/users/physicalAndQuery/' + id, para, true);
  }

  // 查找架构信息
  corporatesPhysical(id): any {
    return this.get('/corporates/physical/' + id, true);
  }

  // 提交巡检
  governmentInspectionSubmit(id): any {
    return this.put('/governments/inspection/governmentInspectionSubmit/' + id, null, true);
  }

  //  提交现场快报
  submitGovernmentInspectionSubmit(para): any {
    return this.post('/governments/inspection/governmentInspectionSubmit/app', para, true);
  }

  deleteCorrectiveActions(id) {
    return this.delete('/correctiveActions/?id=' + id, true);
  }
  // 月度安全绩效排名
  correctiveActionsReportSummary(para): any {
    return this.post('/correctiveActions/report/summary', para, true);
  }
  // 整改报告明细
  correctiveActionsReportDetail(para): any {
    return this.post('/correctiveActions/report/detail', para, true);
  }

  // 检查查询
  inspectionsRecordsList(para): any {
    return this.post('/inspections/record/list', para, true);
  }

  // 检查整改措施记录
  correctiveActionsRecordsList(para): any {
    return this.post('/correctiveActions/records/list', para, true);
  }

  hiddenDangerRecordsList(para): any {
    return this.post('/hiddenDangerReport/list', para, true);
  }

  investigationGroupDetail(para): any {
    return this.post('/investigationTeamMembers/list', para, true);
  }

  createInvestigation(para): any {
    return this.post('/investigationTeamMembers/', para, true);
  }

  updateInvestigation(para): any {
    return this.put('/investigationTeamMembers/', para, true);
  }

  deleteInvestigation(id): any {
    return this.delete('/investigationTeamMembers/' + id, true);
  }

  // 删除图片
  deleteFile(id): any {
    return this.delete('/file?id=' + id, true);
  }
  // 获取图片
  getFile(id): any {
    return this.get('/file/' + id, true);
  }
  getConfig(value): any {
    return this.get('/configurations/' + value, true);
  }
  corporates(id): any {
    return this.get('/corporates/' + id, true);
  }
  // 隐患分类、发生频次汇总报表
  reportHiddenDanger(para): any {
    //  para ={'pagingTool':{'currentPage':1,'pageSize':10},'queryOrderBies':[{'columnName':'id','orderType':'desc'}]};
    return this.post('/correctiveActions/report/hiddenDanger', para, true);
  }
  // 安全检查参与情况报表
  reportSafetyInspection(para): any {
    //  para ={'pagingTool':{'currentPage':1,'pageSize':10},'queryOrderBies':[{'columnName':'id','orderType':'desc'}]};
    return this.post('/correctiveActions/report/safetyInspection', para, true);
  }

  userById(user_id): any {
    return this.get('/users/' + user_id, true);
  }
  getUserList(para): any {
    return this.post('/users/list', para, true);
  }
  corporatesOrgs(): any {
    return this.get('/corporates/orgs/', true);
  }
  taskById(id): any {
    return this.get('/tasks/' + id, true);
  }
  inspectionsCompletes(para, id): any {
    return this.post('/inspections/completes/' + id, para, true);
  }

  // 我的培训
  getMyLessons(para): any {
    return this.post('/training/lessons/app/list', para, true);
  }

  // 课程详情
  getLesson(id): any {
    return this.get(`/training/lessons/app/${id}`, true);
  }

  // 上传图片
  uploadFile(para): any {
    return this.post('/file', para, true);
  }

  // 上传检查报告
  uploadInspectionReport(para): any {
    return this.post('/governments/inspection/pdf2Excel', para, true);
  }

  // 导入Excel数据
  importInspectionDataFromExcel(id: number, originalPdfId: number): any {
    const para = { id, originalPdfId };
    return this.post('/governments/inspection/getDataFromExcel', para, true);
  }

   // 上传图片
   uploadFileApp(para): any {
    return this.post('/file/app', para, true);
  }

  uploadFileNew(para): any {
    return this.post('/file/upload-thumbnail', para, true);
  }

  // 获取工厂下的检查陪同人
  getPhysicalUser(id): any {
    return this.get(`/users/physical/${id}`, true);
  }

  // 获取组织架构下的检查陪同人
  getCorporateUser(id): any {
    return this.get(`/users/corporate/${id}`, true);
  }

  // 现场快报 根据整改责任部门获取整改责任人
  getTentativeAssignee(id): any {
    return this.get(`/users/org/${id}`, true);
  }

  getUsersWithOrgId(id): any {
    return this.get(`/users/org/${id}`, true);
  }


  putTaskSubject(para, id): any {
    return this.put('/inspections/' + id + '/finding/inspection', para, true);
  }

  // 个人EHS绩效考核表
  personalEHSReport(para): any {
    return this.post('/correctiveActions/report/personalEHSReport', para, true);
  }

  // 检查报表
  investigationReport(para): any {
    return this.post('/correctiveActions/report/investigationReport', para, true);
  }

  // 培训报表
  trainingReport(para): any {
    return this.post('/correctiveActions/report/trainingReport', para, true);
  }

  // 获取跟用户相关的组织架构
  listMinByUser(para): any {
    return this.post('/corporates/listMinByUser', para, true);
  }

  // 钉钉登录
  ddLogin(code: string): any {
    return this.get(`/permissions/ding/v2/login/code/${code}`, false);
  }

  // 钉钉登录
  ddLogin1(code: string): any {
    return this.get(`/permissions/ding/login/code/${code}`, false);
  }
    
  // 根据父组织架构获取子组织架构 GET /corporates/{corporateId}/children
  getCorporateByParent(id): any {
    return this.get(`/corporates/${id}/children`, true);
  }

  // 根据组织架构id获取分拨/店/仓加
  getFacilitiesByOrgId(id): any {
    return this.get(`/facilities/listByOrgId/${id}`, true);
  }

  getInspectionTarget(para): any {
    return this.post('/physicalStructure/listByIds', para, true);
  }

  // 获取公告html内容
  getContentHtml(id): any {
    return this.get('/announcement/' + id, true);
  }

  // 隐患列表
  rectificationList(para): any {
    return this.post('/hiddenDangerReport/list', para, true);
  }

  // 根据工厂的id获取详情
  getFacilitiesById(id): any {
    return this.get('/facilities/userFaclity/' + id, true);
  }

  // 对接企业微信
  getWXid(app, gid): any {
    return this.get('/permissions/bestex/login?app=' + app + '&gid=' + gid, false);
  }

  // =====================
  // 爱玛客接口API       
  // =====================

  // 获取任务数量
  getMyTaskCount(): any {
    return this.get('/app/firstPage/myTasks/count', true);
  }

  // 获取任务列表
  getMyTaskList(param): any {
    param.queryCriterias.push({
      condition: '!=',
      connection: 'AND',
      key: 'task_type',
      value: '_SYS_ST3_E22'
    })
    return this.post('/app/firstPage/myTasks/list', param, true);
  }

  // 获取当前组织架构信息
  getCurrentProjectInfo(param): any {
    return this.post('/app/firstPage/projectInfo', param, true);
  }

  getspaceInspection(type, orgId): any {
    return this.get('/spaceInspection/report/' + type + '/' + orgId, true);
  }

  // 获取公告列表
  getAnnouncementList(param): any {
    return this.post('/announcement/list/user', param, true);
  }

  // 获取组织架构列表
  getProjectCostCenterCodeList(param): any {
    return this.post('/corporates/allList', param, true);
  }

  // 获取组织架构列表
  getProjectCostCenterCodeListTable(param): any {
    let passParam = JSON.parse(JSON.stringify(param));
    passParam.queryCriterias.push({
     'connection': 'AND',
     'key': 'noQueryParent',
     'condition': '=',
     'value': 1,
     'isValueADigital': false
    });
    return this.post('/corporates/list/table', passParam, true);
  }

  // 获取人员列表
  getStaffList(param): any {
    let passParams = JSON.parse(JSON.stringify(param));
    return this.post('/employees/autocompletelist', passParams, true);
  }

  // 获取人员列表
  getStaffListCondition(param): any {
    let passParams = JSON.parse(JSON.stringify(param));
    return this.post('/employees/list/condition', passParams, true);
  }

  getCalendarIons(date): any {
    let parame =  {
      'pagingTool': {
        'currentPage': 1,
        'pageSize': 10000
      },
      'queryCriterias': [{
        'connection': 'and',
        'key': 'end_dtm',
        'condition': '<=',
        'value': '2030-03-13T15:59:00.000Z',
        'isValueADigital': false
      }]
    };
    return this.post('/training/trainRecords/calendar', parame, true);
    // return this.post('/employees/autocompletelist', parame, true);
  }

  // 提交事故快报
  submitIncidentReporting(param): any {
    return this.post('/incident/report/submit', param, true);
  }

  // 验证是否同一天同一受伤人员已提交过
  judgeSameReport(data): any{
    return this.post('/incident/report/judgeSame', data, true);
  }

  // 更新事故快报
  updateIncidentReporting(id, data): any {
    return this.put('/incident/report/' + id, data, true);
  }

  // 新增事故调查报告
  creatIncidentInvestigation(data): any {
    return this.post('/incident/report', data, true);
  }

  // 更新事故调查报告
  updateIncidentInvestigation(data): any {
    return this.put('/incident/report', data, true);
  }

  // 统计损失更新接口
  updateIncidentInvestigationLoss(data): any {
    return this.put('/incident/report/statisticalLoss', data, true);
  }

  // 新建整改措施
  createCorrectiveAction(id, data): any {
    return this.post('/incidentTasks/' + id + '/storeInvestigation', data, true);
  }

  // 更新整改措施
  updateCorrectiveAction(id, data): any {
    return this.put('/incidentTasks/' + id + '/updateInvestigation', data, true);
  }

  // 删除整改措施
  deleteCorrectiveAction(taskId, id): any {
    return this.delete('/incidentTasks/' + taskId + '/deleteInvestigation/' + id, true);
  }

  // 获取整改措施list
  // incidentTasks/187/relatedInvestigation
  getCorrectiveAction(id): any {
    return this.get('/incidentTasks/' + id + '/relatedInvestigation', true);
  }

  // 保存统计损失数据
  updateLoss(data): any {
    return this.put('/incident/report/statisticalLoss', data, true);
  }

  // 提交填写的调查报告
  submitReportData(taskId): any {
    return this.post('/taskSignoff/task/' + taskId + '/submit', null, true);
  }

  // 审核调查报告
  returnReport(taskId, data): any {
    return this.post('/taskSignoff/task/' + taskId + '/submit', data, true);
  }

  // 驳回关闭中的事故
  returnAllIncident(taskId, data): any {
    return this.post('/incident/report/close/task/' + taskId, data, true);
  }

  // 根据任务Id获取相关的事故调查报告
  getRelatedReportByTaskId(taskId): any {
    return this.get('/incidentTasks/' + taskId + '/relatedReport', true);
  }

  /* 任务详情 start */
  // 获取任务详情 basicInfo
  getIncidentTaskBasicById(taskId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.get(`/tasks/${taskId}/basicInfo`, true).subscribe(res => {
        res && res['STATUS'] === 0 ? resolve(res) : reject(res);
      });
    });
  }

  // 获取任务详情 detailedInfo
  getIncidentTaskDetailInfoById(taskId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.get(`/incidentTasks/${taskId}/detailedInfo`, true).subscribe(res => {
        res && res['STATUS'] === 0 ? resolve(res) : reject(res);
      });
    });
  }

  // 获取任务详情 report
  getIncidentTaskReportById(taskId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.get(`/incident/report/taskId/${taskId}`, true).subscribe(res => {
        res && res['STATUS'] === 0 ? resolve(res) : reject(res);
      });
    });
  }

  // 获取任务详情 report
  upateIncidentTaskReportById(taskId: number, body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.put(`/incident/report`, body, true).subscribe(res => {
        res && res['STATUS'] === 0 ? resolve(res) : reject(res);
      });
    });
  }

  // 认领 通报中的任务
  assignTaskToMySelfById(taskId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.put(`/incidentTasks/${taskId}/assignToMyself`, {}, true).subscribe(res => {
        res && res['STATUS'] === 0 ? resolve(res) : reject(res);
      });
    });
  }

  // 获取 安排事故调查
  getIncidentDiagnosticById(taskId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.get(`/incidentDiagnostic/assignInvestigationList/${taskId}`, true).subscribe(res => {
        res && res['STATUS'] === 0 ? resolve(res) : reject(res);
      });
    });
  }


  // 获取事故调查报告
  // getIncidentInvestigation(taskId): any {
  //     return this.get('incident/report/'+ taskId + '/byFirstReportId/investigationReport', true);
  // }

  // 获取 事故调查报告
  getIncidentInvestigation(taskId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.get(`/incident/report/${taskId}/byFirstReportId/investigationReport`, true).subscribe(res => {
        res && res['STATUS'] === 0 ? resolve(res) : reject(res);
      });
    });
  }

  //
  getEmployeesDivisionalList(param): Promise<any> {
    return new Promise((resolve, reject) => {
      this.post(`/employees/divisionalList`, param, true).subscribe(res => {
        res && res['STATUS'] === 0 ? resolve(res) : reject(res);
      });
    });
  }

  //提交事故调查 受理中的任务
  submitDiagnosticTask(taskId) {
    return new Promise((resolve, reject) => {
      this.put(`/incidentTasks/${taskId}/submitDiagnostic`, null, true).subscribe(res => {
        res && res['STATUS'] === 0 ? resolve(res) : reject(res);
      });
    });
  }

  //保存 事故调查
  saveIncidentDiagnosticById(taskId: number, body: Array<any>): any {
    return new Promise((resolve, reject) => {
      this.post(`/incidentDiagnostic/${taskId}/list`, body, true).subscribe(res => {
        res && res['STATUS'] === 0 ? resolve(res) : reject(res);
      });
    });
    // return this.post('/incidentDiagnostic/' + taskId + '/list', body, true);
  }

  /* 任务详情 end */

  // 获取历史任务列表
  getPersonalHistoryTaskList(param): any {
    param.queryCriterias.push({
        condition: '!=',
        connection: 'AND',
        key: 'task_type',
        value: '_SYS_ST3_E22'
    })
    return this.post('/tasks/taskCenterPersonalHistory', param, true);
  }

  // 获取待办事项列表
  getPersonalTaskList(param): any {
    param.queryCriterias.push({
      condition: '!=',
      connection: 'AND',
      key: 'task_type',
      value: '_SYS_ST3_E22'
    })
    return this.post('/tasks/taskCenterPersonal', param, true);
  }

  // 获取待领取任务列表
  getPendingTaskList(param): any {
    return this.post('/tasks/taskCenterPending', param, true);
  }

  // 获取执行整改任务详情 taskInfo
  getTaskInfoById(id): any {
    return this.get('/tasks/correctiveAction/' + id, true);
  }

  // 获取执行整改任务详情 detailedInfo
  getTaskDetailInfoById(id): any {
    return this.get('/incidentCorrectiveActions/detailedInfo/' + id, true);
  }

  // 获取执行整改任务相关任务信息 relatedTaskInfo
  getRelatedTaskInfoByTaskId(id): any {
    return this.get('/tasks/correctiveAction/refTask/' + id, true);
  }

  // 获取执行整改任务事故信息 correctiveIncidentInfo
  getIncidentInfoByTaskId(id): any {
    return this.get('/incidents/getByCorrectiveTaskId/' + id, true);
  }

  // 获取执行整改任务详情整改信息 taskContent
  getCorrectiveActionInfo(param): any {
    return this.post('/tasks/correctiveAction/content/list', param, true);
  }

  // 新增执行整改
  saveCorrectiveActionContent(param): any {
    return this.post('/tasks/correctiveAction/content/update', param, true);
  }

  // 更新执行整改
  updateCorrectiveActionContent(param): any {
    return this.put('/tasks/correctiveAction/content/update', param, true);
  }

  // 提交执行整改
  submitCorrectiveActionExecution(taskId, param): any {
    return this.put('/tasks/correctiveAction/' + taskId + '/verify', param, true);
  }

  // 获取验证整改信息
  getCorrectiveActionVerificationInfo(taskId): any {
    return this.get('/tasks/correctiveAction/' + taskId + '/investigationVerification', true);
  }

  // 保存验证整改信息
  updateCorrectiveActionVerificationInfo(param): any {
    return this.post('/tasks/correctiveAction/investigationVerification', param, true);
  }

  // 提交验证整改信息
  submitCorrectiveActionVerification(param, taskId): any {
    return this.post('/taskSignoff/task/' + taskId + '/submit', param, true);
  }

  // 事故记录列表页
  getIncidentRecordList(param): any {
    return this.post('/incidentDiagnostic/task/list', param, true);
  }

  accidentCorrectiveActionsRecordsList(para): any {
    return this.post('/incidentCorrectiveActions/task/list', para, true);
  }

  // ocean 
  // 21.1.4 隐患列表
  getDeviationsList(param): any {
    return this.post('/deviations/list', param, true);
  }

  // 隐患详情
  getDeviationsDetailByTaskId(id): any {
    return this.get('/deviations/' + id, true);
  }

  // 隐患的整改措施列表
  getInspectionCorrectiveActionTasks(id): any {
    return this.get('/deviations/' + id + '/inspectionCorrectiveActionTasks', true);
  }

  // 检查查询列表
  getInspectionTasks(id): any {
    return this.get('/deviations/' + id + '/inspectionTasks', true);
  }

  // 提交隐患上报
  submitHiddenReporting(param): any {
    return this.post('/inspections/issuereporting', param, true);
  }

  // 检查查询
  getInspectionTasksList(param): any {
    return this.post('/inspectionTasks/list', param, true);
  }

  // 检查查询详情
  TaskInfoValue(id): any {
    return this.get('/inspectionTasks/' + id + '/detailedInfo', true);
  }

  TaskBasicById(id): any {
    return this.get('/tasks/' + id + '/basicInfo', true);
  }

  getClaimableFlag(id): any {
    return this.get('/inspectionTasks/' + id + '/claimable', true);
  }

  // 认领
  claimInspectionTasksList(param, id): any {
    return this.put('/inspectionTasks/' + id + '/assignee', param, true);
  }

  getTaskInfoList(id): any {
    return this.get('/inspectionTasks/' + id + '/deviations', true);
  }

  getTaskInfoGeneralList(id): any {
    return this.get('/inspectionTasks/' + id + '/inspectionObjectSummary', true);
  }

  // 保存任务内容
  saveInspectionTasksList(param, id): any {
    // console.log('保存的参数param', param)
    return this.put('/inspectionTasks/' + id + '/deviations', param, true);
  }

  // 提交一次复审
  submitInspectionTasksList(id): any {
    return this.post('/inspectionTasks/' + id + '/submit', null, true);
  }

  // 整改措施列表
  getRectificationMeasuresList(param): any {
    return this.post('/inspectionCorrectiveActions/task/list', param, true);
  }

  // 整改措施详情
  getRectificationDetail(id): any {
    return this.get('/inspectionCorrectiveActions/' + id + '/detailedInfo', true);
  }

  // 整改措施任务内容
  getContentDetail(id): any {
    return this.get('/tasks/correctiveAction/' + id + '/content', true);
  }

  // 保存整改措施
  saveRecordTasksList(param): any {
    return this.put('/tasks/correctiveAction/inspectionContent', param, true);
  }

  submitRecordTasksList(id): any {
    return this.put('/tasks/correctiveAction/' + id + '/verify', null, true);
  }

  // 验证整改
  getInvestigationVerification(id): any {
    return this.get('/tasks/correctiveAction/' + id + '/investigationVerification', true);
  }

  // 保存验证资讯
  saveInvestigationVerification(param): any {
    return this.post('/tasks/correctiveAction/investigationVerification', param, true);
  }

  // 特殊角色驳回
  saveRejected (param): any {
    return this.post('/rejected', param, true);
  }

  // 结案整改措施
  closeSubmitInspectionCA(id): any {
    return this.put('/tasks/correctiveAction/' + id + '/submitInspectionCA', null, true);
  }

  // 相关任务list
  getRelatedTaskExpanded(id): any {
    return this.get('/inspectionTasks/' + id + '/relatedCATask', true);
  }

  // 爱玛客-立即检查
  // 获取检查表格List
  getFormsList(param): any {
    return this.post('/forms/havingPreview/list', param, true);
  }

  // 获取检查表详情
  getFormDetail(id): any {
    return this.get('/forms/' + id + '/preview', true);
  }

  // 获取带大项的表格
  getFormDetailApp(id): any {
    return this.get('/forms/' + id + '/preview/app', true);
  }

  // 创建立即检查
  createImmediateInspection(param): any {
    return this.post('/inspections', param, true);
  }

  // 创建立即检查-提交
  createImmediateInspectionSave(param): any {
    return this.post('/inspections/submit/app', param, true);
  }

  // 暂存立即检查
  saveImmediateInspection(param): any {
    return this.post('/inspections/save/app', param, true);
  }

  // 获取目前有的立即检查的草稿
  getImmediateInspectionDraft() {
    return this.get('/inspections/draft', true);
  }

  // 获取填写了的立即检查数据 + 计划检查
  getAdHocInspection(id): any {
    return this.get('/inspectionTasks/' + id + '/adHocInspection', true);
  }

  // 获取填写了的立即检查数据
  getAdHocInspectionApp(id): any {
    return this.get('/inspectionTasks/' + id + '/adHocInspection/app', true);
  }

  // 获取我的课程list
  getMyClassList(params): any {
    return this.post('/training/trainRecords/rule/list', params, true);
  }

  // 获取课程详情
  getLessonById(id): any {
    return this.get('/training/lessons/' + id, true);
  }

  // 根据id获取课程任务详情
  getLessonTaskById(id,lessonId): any {
    return this.get('/training/trainRecords/lessonTask/' + id + '/' + lessonId, true);
  }

  // 视频轮调
  updataELTask(params): any {
    return this.put('/elTaskResponse', params, true);
  }
  
  // 获取上传后的url
  getAliyunFileUrl(videoId): any {
    return this.get('/aliyun/playInfo/' + videoId, true);
  }
  
  // 计划检查api
  // 获取计划检查基础信息
  getBasicInfoPlanInspection(id): any {
    return this.get('/inspectionTasks/' + id + '/scheduled/detailedInfo', true);
  }

  // 获取计划检查任务的表格list
  getPlanInspectionFormList(id): any {
    return this.get('/inspectionTasks/' + id + '/formList', true);
  }

  // 保存计划检查表格数据
  updatePlanFormData(id, param): any {
    return this.put('/inspectionTasks/' + id + '/inspectionForms', param, true);
  }

  // 提交计划检查
  submitPlanInspection(id): any {
    return this.post('/inspectionTasks/' + id + '/submit', null, true);
  }

  // 转派事故调查报告任务
  turnToSend(para): any {
    return this.put('/incident/report/transfer', para, true);
  }

  // 提交转派的调查报告
  submitTransferReporting(taskId): any {
    return this.put('/incident/report/submit/' + taskId, null, true);
  }

  // 提交转派的调查报告
  submitTransferCorrective(param): any {
    return this.put('/tasks/correctiveAction/transfer', param, true);
  }

  // 提交转派的审核意见
  submitAudit(taskId, response): any {
    return this.put('/incident/report/audit/' + taskId + '/' + response, null, true);
  }

  // QPE 获取服务类型
  getQpeFormCheckList(): any {
    return this.get('/qpeInspection/getServiceType', true);
  }

  // QPE 根据服务类型获取表格
  getQpeFormList(params): any {
    return this.post('/qpeInspection/form/list', params, true);
  }

  // QPE 保存草稿
  getQpeFormSave(params): any {
    return this.put('/qpeInspection/save', params, true);
  }

  // QPE 保存草稿
  getQpeFormSubmit(params): any {
    return this.post('/qpeInspection/submit', params, true);
  }

    // QPE检查查询
    getInspectionQpeList(param): any {
      return this.post('/qpeInspection/list', param, true);
    }

    // 版本号
    getConfigurations(id): any {
      return this.get('/configurations/' + id, true);
    }

    // 医护满意度
    // 获取表格
    getMedicalSatisfactionForm(data):any {
      return this.post('/medicalSatisfactionInspection/form', data, true);
    }

    // 获取org_name
    getOrgNameById(id):any {
      return this.get('/medicalSatisfactionInspection/org/' + id, true);
    }

    // 提交医护满意度
    submieMedicalSatisfactionInspection(data):any {
      return this.post('/medicalSatisfactionInspection/submit/app', data, true);
    }

    // 获取检查邮件群组
    getEmailGorpuByName(name): any {
      return this.get('/messageGroup/' + name + '/byName', true);
    }

    // 提交发送邮件的人员
    submitMessageGroup(data): any{
      return this.post('/messageGroup/shareTask', data, true);
    }

    // 获取运作总监下拉
    getOperateDirectorList(): any{
      return this.get('/qpeInspection/getAllOperateDirector', true);
    }

  // 百世的，需要删除的
  // 事故整改措施记录


  getBestExIncident(id): any {
    return this.get('/bestexIncident/' + id, true);
  }

  getIncidentCounterSign(id): any {
    return this.get('/bestexIncident/byCountersign/' + id, true);
  }

  getIncidentDetailByTaskId(id): any {
    return this.get(`/bestexIncident/byTaskId/${id}`, true);
  }

  //获取会签详细信息
  getCountersignDetail(id): any {
    return this.get('/countersign/' + id, true);
  }

  createCounterSignRecord(para): any {
    return this.post('/countersignRecord', para, true);
  }

  updateCounterSignRecord(para): any {
    return this.put('/countersign', para, true);
  }

  submitCounterSignRecord(para): any {
    return this.post('/countersignRecord/submit', para, true);
  }

  passCounterSign(counterSignId, taskId, para): any {
    return this.post(`/countersign/${counterSignId}/pass/task/${taskId}`, para, true);
  }

  passSurveyReport(incidentId, taskId, para): any {
    return this.post(`/bestexIncident/${incidentId}/auditPassSurveyReport/tasks/pass/${taskId}`, para, true);
  }

  redoSurveyReport(incidentId, taskId, para): any {
    return this.post(`/bestexIncident/${incidentId}/auditPassSurveyReport/tasks/redo/${taskId}`, para, true);
  }

  // 事故记录
  accidentRecordsList(para): any {
    return this.post('/bestexIncident/list', para, true);
  }

  casualtyRecordsList(para): any {
    return this.post('/bestexCasualties/list', para, true);
  }

  casualtyDetail(id): any {
    return this.get('/bestexCasualties/' + id, true);
  }

  updateCasulty(para): any {
    return this.put('/bestexCasualties/', para, true);
  }

  createCasulty(para): any {
    return this.post('/bestexCasualties/', para, true);
  }

  deleteCasulty(id): any {
    return this.delete('/bestexCasualties/' + id, true);
  }

  submitAuditIncidentReport(taskId, referenceId, para): any {
    return this.post('/bestexIncident/' + referenceId + '/auditIncidentReport/tasks/' + taskId, para, true);
  }

  // 新建事故快报
  createBestexIncident(para): any {
    return this.post('/bestexIncident', para, true);
  }

  // 更新事故快报
  updateBestexIncident(para): any {
    return this.put('/bestexIncident', para, true);
  }

  // 提交事故快报
  submitBestexIncident(para): any {
    return this.post('/bestexIncident/submit', para, true);
  }

  submitResearchReport(para, id): any {
    return this.post('/bestexIncident/submitSurveyReport/tasks/' + id, para, true);
  }

  // 报表中心首页安全目标
  homePageSafetyObj(para): any {
    return this.post('/report_module/home_report/safety_goal?year=', para, true);
  }

  // 报表中心设备定检
  regularEquipment(para): any {
    return this.post('/report_module/equipment/equipment_inspection_rate', para, true);
  }

  // 报表中心持证总数
  totalCertificates(para): any {
    return this.post('/report_module/certificate/total_number_of_certificates', para, true);
  }

  // 特种设备作业人员持证率
  getSpecifyEquipTaskRate(para): any {
    return this.post('/report_module/certificate/certificate_holding_rate_of_special_equipment_operators/4', para, true);
  }

  // 获取特种作业人员持证率
  getSpecifyTaskRate(para): any {
    return this.post('/report_module/certificate/certificate_holding_rate_of_special_equipment_operators/3', para, true);
  }

  // 报表中心组织架构
  getHeaderSearch(para): any {
    return this.post('/corporates/listMinByUser', para, true);
  }

  // 首页-事故率
  accidentRateHome(year): any {
    return this.get('/report_module/home_report/accident_rate?year=' + year, true);
  }

  accidentRateHomeByCompany(year): any {
    return this.get('/report_module/home_report/accident_rate_by_company?year=' + year, true);
  }

  // 首页-隐患整改率
  hiddenDangerActionsRate(para): any {
    // POST /report_module/hazard_report/rectification_rate
    // return this.post('/report_module/hazard_report/rectification_rate', para, true);
    return this.post('/report_module/home_report/hidden/group_hidden_danger_data_statistics', para, true);
  }

  // 获取各事业部整改率
  divisionActionsRate(para): any {
    return this.post('/report_module/home_report/hidden/dept_hidden_danger', para, true);
  }

  // 集团新工培训数据
  companyNewEmployeeData(para): any {
    return this.post('/report_module/home_report/total_rate', para, true);
  }

  // 获取新工培训率
  newEmployeeRate(para): any {
    return this.post('/report_module/home_report/business_group_new_job_training_rate', para, true);
  }

  // 隐患整改率
  hiddenRectificationRate(para): any {
    return this.post('/report_module/hazard_report/rectification_rate', para, true);
  }

  // 隐患分级
  rectificationLevel(para): any {
    return this.post('/report_module/hazard_report/hidden_danger_level', para, true);
  }

  // 隐患分类
  rectificationCategory(para): any {
    return this.post('/report_module/hazard_report/hidden_danger_classification', para, true);
  }

  // 首页区域检查汇总
  homeAreaInspection(para): any {
    return this.post('/report_module/inspection/regionsNumber', para, true);
  }

  // 首页单位检查汇总
  homeDeptInspection(para): any {
    return this.post('/report_module/inspection/inspectionUnitsNumber', para, true);
  }

  // 当月累积可记录事故
  cumulativeNearSignAccident(para): any {
    return this.post('/incidentChart/cumulativeNearSignAccident', para, true);
  }

  // 当月累积轻伤+险兆+隐患
  cumulativeRecordableAccident(para): any {
    return this.post('/incidentChart/cumulativeRecordableAccident', para, true);
  }

  // 财年事故分类统计
  fiscalYearcumulativeIncident(para): any {
    return this.post('/dashboardChart/countOfIncidentCategory', para, true);
  }

  // 财年事故数量统计（大区-区域-组织架构）
  fiscalYearCumulativeIncidentNumber(para): any {
    return this.post('/dashboardChart/numberOfIncidentByOrg', para, true);
  }

  // 累计损失工时
  accumulatedLostWorkingHours(para): any {
    return this.post('/incidentChart/accumulatedLostWorkingHours', para, true);
  }

  // 累计事故损失金额
  accumulatedAccidentLossAmount(para): any {
    return this.post('/incidentChart/accumulatedAccidentLossAmount', para, true);
  }

  // 事故数量与损失金额
  numberOfAccidentsAndLossAmount(para): any {
    return this.post('/incidentChart/numberOfAccidentsAndLossAmount', para, true);
  }

  // 累计事故根本原因分析
  accumulativeRootCauseIncidentAmount(para): any {
    return this.post('/incidentChart/rootCause', para, true);
  }

  // 近12个月事故类型分析
  accidentType(para): any {
    return this.post('/dashboardChart/countOfIncidentType', para, true);
  }

  // 近12个月受伤部位分析
  injuredArea(para): any {
    return this.post('/dashboardChart/bodyHurtNearly12Month', para, true);
  }

  // 近12个月事故发生时间段
  occurrenceTime(para): any {
    return this.post('/dashboardChart/periodOfIncidentTypeNearly12Moth', para, true);
  }

  // 2019-今 员工年龄与损失
  employeeAgeAndLoss(para): any {
    return this.post('/incidentChart/injuredPersonAge', para, true);
  }

  // 近12个月整改完成趋势分析
  rectifyDoneTrend(para): any {
    return this.post('/dashboardChart/trendOfIncidentCorrNearly12Month', para, true);
  }

  // 近12个月事故分类整改分析
  categoryRectify(para): any {
    return this.post('/dashboardChart/trendOfIncidentCorrNearly12MonthByIncidentCategory', para, true);
  }

  // 近12个月事故整改完成率（大区、区域、组织架构）
  rectifyDoneRate(para): any {
    return this.post('/dashboardChart/trendOfIncidentCorrNearly12MonthByOrg', para, true);
  }

  // 近三年事故率
  commonlyAccident(para): any {
    return this.post('/dashboardChart/accidentRate', para, true);
  }

  // 近三年严重事故率
  seriousAccident(para): any {
    return this.post('/dashboardChart/accidentRateSerious', para, true);
  }
  // 食安报表-起
  // 获取级联选择器检查类型和检查表
  getCheckTypeAndTable (bsLine) {
    const para = {
      queryCriterias: [
      {
        connection: "and",
        key: "org_id",
        condition: "in",
        value: bsLine,
        isValueADigital: true
      }
      ]
    };
    return this.post('/forms/havingPreview/list/tree', para, true);
  }
    // 事故占比--按【异物种类】统计
    getMatterStatistics(para): any {
      return this.post('/incidentChart/foreignBodyAccumulationStatistics', para, true)
    }
    // 事故占比--按【异物种类】统计
    getPossibleResourceMatterCount(para): any {
      return this.post('/incidentChart/suspectedSourceOfForeignBody', para, true)
    }
    // 事件类型分析
    getEventTypeAnalysis(para): any {
      return this.post('/dashboardChart/analyseOfIncidentTypeFoodSafety', para, true)
    }
    // 餐线发生时间分析
    getMealLineHappendTime(para): any {
      return this.post('/dashboardChart/analyseOfIncidentTimeFoodSafety', para, true)
    }
    // 整改完成趋势分析
    getRectificationCompletionTrendAnalysis(para): any {
      return this.post('/dashboardChart/trendOfIncidentCorrNearly12MonthFoodSafety', para, true)
    }
    // 事故整改完成率（大区、区域、组织架构）
    getAccidentRectificationCompletionRate(para): any {
      return this.post('/dashboardChart/trendOfIncidentCorrNearly12MonthByOrgFoodSafety', para, true)
    }
    // 事故分类整改分析
    getAccidentCorrectionAnalysis(para): any {
      return this.post('/dashboardChart/trendOfIncidentCorrNearly12MonthByIncidentCategoryFoodSafety', para, true)
    }
    // 关键违规系数趋势
    getViolationCoefficient(para): any {
      return this.post('/dashboardChart/trendOfKeyViolationCoefficient', para, true)
    }
    // 关键项违规明细
    getDetailsViolationCoefficient(para): any {
      return this.post('/dashboardChart/detailOfKeyViolationCoefficient', para, true)
    }
    // 关键违规系数
    getKeyViolationCoefficient(para): any {
      return this.post('/dashboardChart/keyViolationCoefficientCount', para, true)
    }
  // 食安报表-止

  // 评估问题分析-起
    // 获取表格大项、风险等级ID
    getSystemCodes(code: String){
      return this.get(`/codes/children?code=${code}`,true);
    }
    // 获取表格分区ID
    getTablePartition(tableId: String){
      return this.get(`/forms/${tableId}/sections`,true);
    }
    // 评估项失分频次分析
    getFrequencyOfLosingPoints(para) {
      return this.post('/dashboardChart/freqOfLoss', para, true)
    }
    // 评估项失分频次趋势
    getTrendOfLosingPoints(para) {
      return this.post('/dashboardChart/trendOfLoss', para, true)
    }
    // 得分分析 - 平均分（大区、区域、组织架构）  
    scoreAvg(para): any {
      return this.post('/dashboardChart/analyseOfElementAVG', para, true)
    }
  // 评估问题分析-终

  // 隐患及5s分析-起
    // 获取隐患分析的数据
    getIssueAnalysis(para): any {
      return this.post('/dashboardChart/trendOfAccumulateDeviation', para, true)
    }
    // 累计5s分析的数据
    getCumulative5sAnalysis(para): any {
      return this.post('/dashboardChart/trendOfAccumulate5SDeviation', para, true)
    }
    // 累计事故根本原因分析
    getIssueReason(para): any {
      return this.post('/dashboardChart/rootCauseOfDeviationNearly12Month', para, true)
    }
    // 隐患分类统计
    getIssueClassified(para): any {
      return this.post('/dashboardChart/classificationOfDeviationNearly12Month', para, true)
    }
  // 隐患及5s分析-终

  // ATP分析报表-起
    // ATP合格率分析（业务线、大区、区域、组织架构）
    getATPPassRate(para) {
      return this.post('/dashboardChart/analyseOfATP', para, true)
    }
    // ATP合格率趋势分析
    getTrendOfATPPassRate(para) {
      return this.post('/dashboardChart/trendOfATP', para, true)
    }
    // ATP月度平均分趋势分析
    getMonthTrendOfATPPassRate(para) {
      return this.post('/dashboardChart/trendOfATPAVG', para, true)
    }
    // ATP月度平均分分析
    getMonthOfATPPassRate(para) {
      return this.post('/dashboardChart/analyseOfATPAVG', para, true)
    }
  // ATP分析报表-止

  // 获取企业微信jssdk配置
  getWechatJSSDKConfig(): any {
    return this.get('/permissions/info/weChart', true);
  }

  getInfoConfig(): any {
    return this.post('/permissions/info', null, true);
  }

  /***
   * ocean 13/4/2021
   * 开始试卷接口调用
   * ***/
   getTestPaperList(id1,id2,id3): any {
    return this.get('/testPaper/getByCombinationConditions/' + id1 + '/' + id2 + '/' + id3 , true);
  }

   /***
   * ocean 13/4/2021
   * 提交试卷接口
   * ***/
   submitTestPaperList(id1,id2,para): any {
    return this.post('/testPaper/submit/' + id1 + '/' + id2, para, true);
  }

  // 组织架构
  queryGroups(query: string, orgId?: string) {
    const queryUtils = this.generateQueryUtil('_SYS_A8_5', query, orgId);
    return this.post('/corporates/list/table', queryUtils, true);
  }

  // 区域
  queryRegion(query: string, orgId?: string) {
    const queryUtils = this.generateQueryUtil('_SYS_A8_4', query, orgId);
    return this.post('/corporates/list/table', queryUtils, true);
  }

  // 大区
  queryDivision(query: string, orgId?: string) {
    const queryUtils = this.generateQueryUtil('_SYS_A8_3', query, orgId);
    return this.post('/corporates/list/table', queryUtils, true);
  }

  // 业务线
  queryBusinessLine(query: string, orgId?: string) {
    const queryUtils = this.generateQueryUtil('_SYS_A8_2', query, orgId);
    return this.post('/corporates/list/table', queryUtils, true);
  }

  // 根据类型获取组织架构
  queryOrg(query?: string, orgId?: string, type?: string) {
    if (type == '_SYS_A8_2') orgId = null;
    const queryUtils = this.generateQueryUtil(type, query, orgId);
    return this.post('/corporates/list/table', queryUtils, true);
  }

  queryOrgCompany(query: string, orgId?: string, type?: string) {
    if (type == "_SYS_A8_2") orgId = null;
    if (type === '_SYS_A8_5' && !orgId) orgId = null;
    const queryUtils = this.generateQueryUtil(type, query, orgId);
    return this.post('/corporates/list/table/dashboard', queryUtils, true);
  }

  // 获取当前用户的默认组织架构
  getDefaultOrg():any {
    return this.get('/corporates/list/table/default/all', true);
  }

  getDefaultOrgByBL(bl):any {
    return this.get('/corporates/list/table/default/' + bl, true);
  }

  generateQueryUtil(orgType, queryInput?, parentId?) {
    const queryUtils = {
      pagingTool: {
        currentPage: 1,
        pageSize: 100
      },
      queryCriterias: [],
      queryOrderBies: [
      {
        columnName: 'code',
        orderType: 'asc'
      }
      ]
    };

    if (queryInput) {
      queryUtils.queryCriterias.push({
        connection: 'and', 
        key: 'code', 
        condition: 'like', 
        value: queryInput, 
        isValueADigital: false
      });
    }
    if (orgType) {
      queryUtils.queryCriterias.push({
        connection: 'and', 
        key: 'org_type', 
        condition: 'like', 
        value: orgType, 
        isValueADigital: false
      });
    }
    if (parentId) {
      queryUtils.queryCriterias.push({
        connection: 'and', 
        key: 'parent_id', 
        condition: 'in', 
        value: parentId, 
        isValueADigital: false
      });
    }

    return queryUtils;
  }
  
  generateCascaderQueryUtil(para, flag?) {
    const queryUtil = {
      pagingTool: {
        'currentPage': 0,
        'pageSize': 1000
      },
      queryCriterias: [],
      params: {},
    }
    if (para.businessLine) {
      const obj = {
        'connection': 'and',
        'key': 'org_business_line',
        'condition': 'in',
        'value': para.businessLine.id,
        'isValueADigital': true
      }
      queryUtil.queryCriterias.push(obj);
    }
    if (para.org_area) {
      const obj = {
        'connection': 'and',
        'key': 'org_area',
        'condition': 'in',
        'value': para.org_area.id,
        'isValueADigital': true
      }
      queryUtil.queryCriterias.push(obj);
    }
    if (para.org_operation) {
      const obj = {
        'connection': 'and',
        'key': 'org_operation',
        'condition': 'in',
        'value': para.org_operation.id,
        'isValueADigital': true
      }
      queryUtil.queryCriterias.push(obj);
    }
    if (para.org_program) {
      const obj = {
        'connection': 'and',
        'key': 'org_program',
        'condition': 'in',
        'value': para.org_program.id,
        'isValueADigital': true
      };
      queryUtil.queryCriterias.push(obj);
    }

    if (para.startTime) {
      queryUtil['params']['startYear'] = new Date(para.startTime).getFullYear();
      queryUtil['params']['startMonth'] = new Date(para.startTime).getMonth() + 1;
    }

    if (para.endTime) {
      queryUtil['params']['endYear'] = new Date(para.endTime).getFullYear();
      queryUtil['params']['endMonth'] = new Date(para.endTime).getMonth() + 1;
    }

    if (flag === 'training') {
      if (para.courseType) {
        queryUtil['params']['type'] = para.courseType;
      }
    }
    if (flag === 'certificate') {
      if (para.maintenanceDept) {
        const obj = {
          "connection": "and",
          "key": "maintenance_department",
          "condition": "=",
          "value": para.maintenanceDept,
          "isValueADigital": false
        };
        queryUtil.queryCriterias.push(obj);
      }
    }
    return queryUtil;
  }

  // ----------检查整改分析-------------
  // 检查整改完成趋势分析
  getInspectionCorrectionTrend(query) {
    const queryUtil = this.generateCascaderQueryUtil(query);
    return this.post('/dashboardChart/completionRateOfCorrect', queryUtil, true);
  }

  // 验证周期分析
  getVerificationCycleAnalysis(query) {
    const queryUtil = this.generateCascaderQueryUtil(query);
    return this.post('/dashboardChart/analyseOfCorrVerityCycle', queryUtil, true);
  }

  // 组织架构整改分析
  getProjectCorrectionAnalysis(query) {
    const queryUtil = this.generateCascaderQueryUtil(query);
    return this.post('/dashboardChart/analyseOfCorrByOrgName', queryUtil, true);
  }

  // 检查整改完成率 （大区，区域，组织架构）
  getCorrectionCompletionRate(query, orgType) {
    const queryUtil = this.generateCascaderQueryUtil(query);
    queryUtil['params']['orgType'] = orgType;
    return this.post('/dashboardChart/completionRateOfCorrectOrg', queryUtil, true);
  }

  // 综合统计
  getOverallStatistic(query) {
    const queryUtil = this.generateCascaderQueryUtil(query);
    return this.post('/dashboardChart/analyseOfCorrComplex', queryUtil, true);
  }

  // 二期 空间检查 karin

  // 获取空间list
  getSpaceList(param):any {
    return this.post('/space/dropDown/list', param, true);
  }

  // 根据空间获取表格list
  getFormListBySpaceId(param): any {
   return this.post('/forms/havingPreview/list', param, true);
 }

  // 提交空间检查
  submitSpaceInspection(param): any {
   return this.post('/spaceInspection/submit/app', param, true);
 }

  // 提交计划性空间检查
  submitScheduledSpaceInspection(param): any {
   return this.post('/spaceActivity/submit/app', param, true);
 }

  // 根据空间ID获取检查对象list
  getEmployeeBySpaceId(param, spaceId): any {
   let passParam = JSON.parse(JSON.stringify(param));
   passParam.params = {
    space_id: spaceId
  };
  return this.post('/space/getEmployeeBySpaceId', passParam, true);
}

  // 根据空间ID和组织架构ID获取检查对象list
  getEmployeeByOrgIdAndSpaceId(param, orgId, spaceId): any {
   let passParam = JSON.parse(JSON.stringify(param));
   passParam.queryCriterias.push({
    'connection': 'and',
    'key': 'org_ids',
    'condition': '=',
    'value': orgId,
  });
   passParam.queryCriterias.push({
    'connection': 'and',
    'key': 'spaceId',
    'condition': '=',
    'value': spaceId,
  });
   return this.post('/employees/autocompletelist/space', passParam, true);
 }

  // 获取空间检查记录列表
  getSpaceInspectionList(param, type): any {
   let passParam = JSON.parse(JSON.stringify(param));
   passParam.queryCriterias.push({
    'connection': 'AND',
    'key': 'serviceName',
    'condition': '=',
    'value': type,
    'isValueADigital': false
  });
   return this.post('/spaceInspection/list', passParam, true);
 }

  // 空间检查记录详情主要信息
  getSpaceInspectionMainInfo(taskId): any {
   return this.get('/inspectionTasks/' + taskId + '/detailedInfo', true);
 }

  // 空间检查记录详情表格信息
  getSpaceInspectionFormInfo(taskId): any {
   return this.get('/inspectionTasks/' + taskId + '/adHocInspection', true);
 }

  // 患者满意度调查提交
  submitPatientSatisfactorySurvey(param): any {
   return this.post('/patientSatisfactionInspection/submit/app', param, true);
 }

  // 患者满意度调查记录列表
  getSatisfactionSurveyList(param, serviceType): any {
   let passParam = JSON.parse(JSON.stringify(param));
   passParam.queryCriterias.push({
    'connection': 'AND',
    'key': 'serviceType',
    'condition': '=',
    'value': serviceType,
    'isValueADigital': false
  });
   return this.post('/patientSatisfactionInspection/list', passParam, true);
 }

  // 患者满意度调查记录详情主要信息
  getSatisfactionSurveyMainInfo(taskId): any {
   return this.get('/inspectionTasks/' + taskId + '/detailedInfo', true);
 }

  // 患者满意度调查记录详情表格信息
  getSatisfactionSurveyFormInfo(taskId): any {
   return this.get('/inspectionTasks/' + taskId + '/adHocInspection', true);
 }

   // 岗位评估记录详情主要信息
  getJobEvaluationMainInfo(taskId): any {
   return this.get('/inspectionTasks/' + taskId + '/detailedInfo', true);
 }

  // 岗位评估记录详情表格信息
  getJobEvaluationFormInfo(taskId): any {
   return this.get('/inspectionTasks/' + taskId + '/adHocInspection', true);
 }

  // 提交岗位评估
  submitJobEvaluation(param): any {
    return this.post('/jobEvaluationInspection/submit', param, true);
  }

  // 岗位评估记录列表
  getJobEvaluationList(param, serviceType): any {
    let passParam = JSON.parse(JSON.stringify(param));
    passParam.queryCriterias.push({
      'connection': 'AND',
      'key': 'serviceType',
      'condition': '=',
      'value': serviceType,
      'isValueADigital': false
    });
    return this.post('/jobEvaluationInspection/list', passParam, true);
  }
  
  // 获取所有整改
  getAllCorrection(query) {
    const queryUtil = this.generateCascaderQueryUtil(query);
    return this.post('/dashboardChart/completionRateOfCorrectAll', queryUtil, true);
  }
  // ----------------------------------
  // 隐患数量及整改分析
  getIssueAndCorrection(query) {
    const queryUtil = this.generateCascaderQueryUtil(query);
    return this.post('/dashboardChart/completionRateOfCorrectDeviation', queryUtil, true);
  }

  // 获取qpe-上级
  getAllLeader(id): any {
    return this.get('//qpeInspection/getAllLeader/' + id, true);
  }

  // Dashboard 关键目标
  getKeyTargets(query) {
    const queryUtil = this.generateCascaderQueryUtil(query);
    return this.post('/dashboardChart/titles', queryUtil, true);
  }

  // Dashboard 整改率
  getCorrectionRate(query) {
    const queryUtil = this.generateCascaderQueryUtil(query);
    return this.post('/dashboardChart/correctiveAction', queryUtil, true);
  }

  // Dashboard 培训率
  getTrainingRate(query) {
    const queryUtil = this.generateCascaderQueryUtil(query);
    return this.post('/dashboardChart/train', queryUtil, true);
  }

  // Dashboard 排名
  getRanking(query) {
    const queryUtil = this.generateCascaderQueryUtil(query);
    return this.post('/dashboardChart/complex', queryUtil, true);
  }

  // 培训报表（按时间）
  getTrainingByMonth(para) {
    const queryUtil = this.generateCascaderQueryUtil(para, 'training');
    return this.post('/dashboardChart/trainRateOfNearly12Month', queryUtil, true);
  }

  // 培训报表（按课程）
  getTrainingByCourse(para) {
    const queryUtil = this.generateCascaderQueryUtil(para, 'training');
    return this.post('/dashboardChart/trainRateOfNearly12MonthLesson', queryUtil, true);
  }

  // 证件过期与占比
  getCertificateValidation(para) {
    const queryUtil = this.generateCascaderQueryUtil(para, 'certificate');
    return this.post('/dashboardChart/expiredCertificate', queryUtil, true);
  }

  // 持证人数
  getCertificateCount(para) {
    const queryUtil = this.generateCascaderQueryUtil(para, 'certificate');
    return this.post('/dashboardChart/numOfCertificateContain', queryUtil, true);
  }

  // 修改密码
  changePassword(pwd) {
    return this.put('/users/changePW', pwd, true)
  }

  // 评估分析报表内容
  // 获取home page list
  getHomePageList(param) {
    const query = {
      pagingTool: {
        currentPage: 0,
        pageSize: 100
      },
      params: {
        year: Number(param.year),
        month: Number(param.month)
      }
    }
    return this.post('/evaluationItemChart/front', query, true);
  }

  getEmployeeByOrgId(searchMsg, id) {
    const queryUtil = this.generateBasicQueryUtil();
    if (id) {
      queryUtil.queryCriterias.push({
        connection: 'and',
        key: 'org_ids',
        condition: '=',
        value: id,
        isValueADigital: false
      });
    }
    if (searchMsg) {
      queryUtil.queryCriterias.push({
        connection: 'and',
        key: 'code',
        condition: 'like',
        value: searchMsg,
        isValueADigital: false
      });
    }
    return this.post('/employees/autocompletelist', queryUtil, true);
  }

  generateBasicQueryUtil() {
    return {
      pagingTool: {
        currentPage: 1,
        pageSize: 50
      },
      queryCriterias: [],
      queryOrderBies: []
    }
  }

  // 豫园api
  // 合规文件
  getEditPageData(id): any{
    return this.get('/compliantDocument/editPageData/' + id, true);
  }

  // 获取合规文件详情
  getCompliantDocumentCategory(id): any{
    return this.get('/compliantDocumentCategory/' + id, true);
  }

  // 新建合规文件
  createCompliantDocument(data): any{
    return this.post('/compliantDocument', data, true);
  }

  // 更新合规文件
  updateCompliantDocument(data): any{
    return this.put('/compliantDocument', data, true);
  }

  // 删除合规文件
  deleteDocFile(id): any{
    return this.delete('/compliantDocument/' + id, true);
  }

  queryForm(query: string, excludeMedical?: boolean) {
    const queryUtils = {
      pagingTool: {
        currentPage: 1,
        pageSize: 35
      },
      queryCriterias: [],
      queryOrderBies: [
        {
          columnName: 'id',
          orderType: 'desc'
        }
      ]
    };
    queryUtils.queryCriterias = [
      ...(query ? [new QueryCriteria('code', query, 'like', 'and')] : []),
      // ...(query ? [new QueryCriteria('formName', query, 'like')] : []),
    ];
    if (excludeMedical) {
      queryUtils.queryCriterias.push(...([new QueryCriteria('serviceType', '_SYS_WH_14_1', '=')]));
    }
    return this.post('/forms/havingPreview/list', queryUtils, true);
  }

  // 获取第三方检查详情
  getGovernmentDetail(taskId): any{
    return this.get('/inspections/government/' + taskId, true);
  }

  // 获取第三方检查list
  getGovList(params): any{
    return this.post('/inspections/government/list', params, true);
  }

  // 获取第三方检查list
  getGovCorrectiveList(params): any{
    return this.post('/inspectionCorrectiveActions/task/list', params, true);
  }
  // 组织架构层级结构
  getCorporatesChildrenById(id): any{
    return this.get('/corporates/getChildrenWithoutInvalid/' + id, true);
  }  
  // 第三方检查上报api
  // 创建第三方检查
  createGovReportSave(param): any {
    return this.put('/inspections/government/submit', param, true);
  }

  // 获取登陆人员最高组织架构
  getHighOrg(): any{
    return this.get('/corporates/getChildren/first', true);
  }
  
  // 获取是否有最优实践
  getHasOptimal(): any {
    return this.get('/configurations/best-practice.enable', true);
  }

  // app首页当月数据
  getNnmbersList(params): any {
    return this.post('/app/firstPage/home', params, true);
  }
}
