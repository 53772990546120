import { NgModule, isDevMode } from '@angular/core';
// import { environment } from 'src/environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JPush } from '@jiguang-ionic/jpush/ngx';
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {HttpClient, HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import { SharedModule } from './shared';
import { CoreModule } from './core';
import { PipesModule } from './pipes/pipes.module';
import { Device } from '@ionic-native/device/ngx';
// import { AppVersion } from '@ionic-native/app-version/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NativeService } from './providers/NativeService';
import { DataService, AppService, LocalStorageService, PopDataService, GovDataService, HiddenDataService, IncidentPopDataService, 
  SpaceCheckService, MenuService, JobsEvaluationService, QpeDataService, DocumentDataService, ImmediateFindingDataService } from './service';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { ImagePicker } from '@ionic-native/image-picker/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { PreviewPictureComponent } from './shared/components/preview-picture/preview-picture.component';
import { TaskDetailObjectModalComponent} from './task-detail-object-modal/task-detail-object-modal.component';
import { TaskchkAssignModalComponent} from './taskchk-assign-modal/taskchk-assign-modal.component';
import { LiveNewsletterCopyComponent } from './live-newsletter-copy/live-newsletter-copy.component';
import { PerformValidationHistoryComponent } from './perform-validation-history/perform-validation-history.component';
import { TaskAssignModalComponent } from './task-assign-modal/task-assign-modal.component';
import { CorpSelectComponent } from './shared/components/corp-select/corp-select.component';
import { CorpSelectReportComponent } from './shared/components/corp-select-report/corp-select-report.component';
import { AddPartyIndividualComponent } from './add-party-individual/add-party-individual.component';
import { JWTInterceptor } from './service/interceptor.service';
import { Toast } from '@ionic-native/toast/ngx';  
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '../../../assets/i18n/', '.json');
  // 生产环境需要使用带app的路径
  // return new TranslateHttpLoader(http, environment.production ? '../../app/assets/i18n/' : '../../../assets/i18n/', '.json');
}

import { CalendarModule } from 'ion2-calendar';
@NgModule({
  declarations: [
  AppComponent,
  PreviewPictureComponent,
  LiveNewsletterCopyComponent,
  TaskchkAssignModalComponent,
  PerformValidationHistoryComponent,
  TaskAssignModalComponent,
  CorpSelectComponent,
  CorpSelectReportComponent,
  AddPartyIndividualComponent,
  ],
  entryComponents: [
  PreviewPictureComponent,
  LiveNewsletterCopyComponent,
  TaskchkAssignModalComponent,
  PerformValidationHistoryComponent,
  TaskAssignModalComponent,
  CorpSelectComponent,
  CorpSelectReportComponent,
  AddPartyIndividualComponent,
  ],
  imports: [
  BrowserModule,
  IonicModule.forRoot( {
    backButtonText: '',
    // tabsHideOnSubPages: 'true',
    // iconMode: 'ios',
    animated: true,
    hardwareBackButton: true,
    swipeBackEnabled: true,
    mode: 'ios',
    // preloadModules: true
  }),
  AppRoutingModule,
  HttpClientModule,
  ReactiveFormsModule,
  FormsModule,
  SharedModule,
  CoreModule,
  IonicStorageModule.forRoot(),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  }),
  PipesModule,
  PdfViewerModule,
  CalendarModule,
  BrowserAnimationsModule,
  ],
  providers: [
  StatusBar,
  SplashScreen,
  Device,
  DataService,
  AppService,
  LocalStorageService,
  PopDataService,
  GovDataService,
  SpaceCheckService,
  JobsEvaluationService,
  IncidentPopDataService,
  QpeDataService,
  HiddenDataService,
  DocumentDataService,
  ImmediateFindingDataService,
  MenuService,
  NativeService,
  Camera,
  Diagnostic,
  ImagePicker,
  Toast,
  File,
  FileTransfer,
  DocumentViewer,
  InAppBrowser,
  Geolocation,
  AndroidPermissions,
  BarcodeScanner,
  { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  JPush,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: JWTInterceptor,
    multi: true
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
